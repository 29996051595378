export const  searchBarData=[
    {
      id:1,
      product:'2 piece cloth'
    },
    {
      id:2,
      product:'vintage shirt'
    },
    {
      id:3,
      product:'joggers'
    },
    {
      id:4,
      product:'t-shirt'
    },
    {
      id:5,
      product:'wine dress'
    },
    {
      id:6,
      product:'crop top'
    },
    {
      id:7,
      product:'Female skiny jean'
    },
    {
      id:8,
      product:'Denim pallazo'
    },
    {
      id:9,
      product:'Riped jean'
    },
    {
      id:10,
      product:'Light blue jean'
    },
    {
      id:11,
      product:'Pink jogger'
    },
    {
      id:12,
      product:'Slit flare culottes'
    },
    {
      id:13,
      product:'Casual short sleeve'
    },
    {
      id:14,
      product:'Empari Apparel'
    },
    {
      id:15,
      product:'Coporate ladie suit'
    },
    {
      id:16,
      product:'Orange gown'
    },
    {
      id:17,
      product:'Long gown'
    },
    {
      id:18,
      product:'Coporate dress'
    },
    {
      id:19,
      product:'Office dress'
    },
    {
      id:20,
      product:'Polka dot dress'
    },
    {
      id:21,
      product:'Striped pants combo'
    },
    {
      id:22,
      product:'Bell sleeves crop to'
    },
    {
      id:23,
      product:'White Body suit'
    },
    {
      id:24,
      product:'Striped lapel'
    },
    {
      id:25,
      product:'Hilfigher hoodie'
    },
    {
      id:26,
      product:'Black trouser'
    },
    {
      id:27,
      product:'Blue lean'
    },
    {
      id:28,
      product:'Cargo joggers'
    },
    {
      id:29,
      product:'El-vin GREY pack'
    },
    {
      id:30,
      product:'Offwhite sweater'
    },
    {
      id:31,
      product:'Baggy joggers'
    },
    {
      id:32,
      product:'Coperate trouser'
    },
    {
      id:33,
      product:'Striped suit trouser'
    },
    {
      id:34,
      product:'Wine suit'
    },
    {
      id:35,
      product:'Tuxedo suit'
    },
    {
      id:36,
      product:'Diamond floral suit'
    },
    {
      id:37,
      product:'Brown chinok'
    },
    {
      id:38,
      product:'Baggy trouser'
    },
    {
      id:39,
      product:'White designer pants'
    },
  ]



export const womensclotheProd=[
    {
        "no": 119,
        "id": "c19-clothe",
        "productname": "Female skiny jean",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c19-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 120,
        "id": "c20-clothe",
        "productname": "Denim pallazo",
        "productprice": 7000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c20-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 121,
        "id": "c21-clothe",
        "productname": "Riped jean",
        "productprice": 6750,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c21-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 122,
        "id": "c22-clothe",
        "productname": "Light blue jean",
        "productprice": 6750,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c22-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 123,
        "id": "c23-clothe",
        "productname": "Pink joggers",
        "productprice": 6600,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c23-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 124,
        "id": "c24-clothe",
        "productname": "Slit flare culottes",
        "productprice": 12000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c24-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 125,
        "id": "c25-clothe",
        "productname": "Casual short sleeve",
        "productprice": 3400,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c25-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 126,
        "id": "c26-clothe",
        "productname": "Casual short sleeve",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c26-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 127,
        "id": "c27-clothe",
        "productname": "Wine dress",
        "productprice": 8500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c27-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 128,
        "id": "c28-clothe",
        "productname": "Empari Apparel",
        "productprice": 1100,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c28-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 129,
        "id": "c29-clothe",
        "productname": "Coporate ladie suit",
        "productprice": 9900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c29-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 130,
        "id": "c30-clothe",
        "productname": "Orange gown",
        "productprice": 5500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c30-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 131,
        "id": "c31-clothe",
        "productname": "Long gown",
        "productprice": 3900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c31-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 132,
        "id": "c32-clothe",
        "productname": "Coporate dress",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c32-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 133,
        "id": "c33-clothe",
        "productname": "Office dress",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c33-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 134,
        "id": "c34-clothe",
        "productname": "Polka dot dress",
        "productprice": 6590,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c34-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 135,
        "id": "c35-clothe",
        "productname": "Striped pants combo",
        "productprice": 5999,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c35-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 136,
        "id": "c36-clothe",
        "productname": "Crop top",
        "productprice": 3500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c36-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 137,
        "id": "c37-clothe",
        "productname": "Bell sleeves crop top",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c37-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 138,
        "id": "c38-clothe",
        "productname": "White Body suit",
        "productprice": 3000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c38-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    
]
export const mensclotheProd=[
    {
        "no": 101,
        "id": "c1-clothe",
        "productname": "2 piece cloth",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c1-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 102,
        "id": "c2-clothe",
        "productname": "Vintage shirt",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c2-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 103,
        "id": "c3-clothe",
        "productname": "Striped lapel",
        "productprice": 5300,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c3-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 104,
        "id": "c4-clothe",
        "productname": "Hilfigher hoodie",
        "productprice": 8000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c4-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 105,
        "id": "c5-clothe",
        "productname": "Black trouser",
        "productprice": 5900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c5-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 106,
        "id": "c6-clothe",
        "productname": "Blue lean",
        "productprice": 28000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c6-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 107,
        "id": "c7-clothe",
        "productname": "Joggers",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c7-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 108,
        "id": "c8-clothe",
        "productname": "Cargo joggers",
        "productprice": 8900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c8-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 109,
        "id": "c9-clothe",
        "productname": "El-vin GREY pack",
        "productprice": 32000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 110,
        "id": "c10-clothe",
        "productname": " Offwhite sweater",
        "productprice": 6500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 111,
        "id": "c11-clothe",
        "productname": "Baggy joggers",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 112,
        "id": "c12-clothe",
        "productname": "T-shirt",
        "productprice": 4590,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 113,
        "id": "c13-clothe",
        "productname": "Coperate trouser",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 114,
        "id": "c14-clothe",
        "productname": "Striped suit trouser",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 115,
        "id": "c15-clothe",
        "productname": "Wine suit",
        "productprice": 20000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 116,
        "id": "c16-clothe",
        "productname": "Tuxedo suit",
        "productprice": 39000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 117,
        "id": "c17-clothe",
        "productname": "Diamond floral suit",
        "productprice": 27000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c17-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 118,
        "id": "c18-clothe",
        "productname": "Brown chinok",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c18-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    
    {
        "no": 139,
        "id": "c39-clothe",
        "productname": "Baggy trouser",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/39-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 140,
        "id": "40-clothe",
        "productname": "White designer pants",
        "productprice": 3000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/40-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
]



export const allclotheProd=[
    {
        "no": 119,
        "id": "c19-clothe",
        "productname": "Female skiny jean",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c19-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 120,
        "id": "c20-clothe",
        "productname": "Denim pallazo",
        "productprice": 7000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c20-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 121,
        "id": "c21-clothe",
        "productname": "Riped jean",
        "productprice": 6750,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c21-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 122,
        "id": "c22-clothe",
        "productname": "Light blue jean",
        "productprice": 6750,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c22-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 123,
        "id": "c23-clothe",
        "productname": "Pink joggers",
        "productprice": 6600,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c23-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 124,
        "id": "c24-clothe",
        "productname": "Slit flare culottes",
        "productprice": 12000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c24-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 125,
        "id": "c25-clothe",
        "productname": "Casual short sleeve",
        "productprice": 3400,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c25-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 126,
        "id": "c26-clothe",
        "productname": "Casual short sleeve",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c26-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 127,
        "id": "c27-clothe",
        "productname": "Wine dress",
        "productprice": 8500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c27-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 128,
        "id": "c28-clothe",
        "productname": "Empari Apparel",
        "productprice": 1100,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c28-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 129,
        "id": "c29-clothe",
        "productname": "Coporate ladie suit",
        "productprice": 9900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c29-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 130,
        "id": "c30-clothe",
        "productname": "Orange gown",
        "productprice": 5500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c30-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 131,
        "id": "31-clothe",
        "productname": "Long gown",
        "productprice": 3900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c31-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 132,
        "id": "32-clothe",
        "productname": "Coporate dress",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c32-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 133,
        "id": "c33-clothe",
        "productname": "Office dress",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c33-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 134,
        "id": "c34-clothe",
        "productname": "Polka dot dress",
        "productprice": 6590,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c34-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 135,
        "id": "c35-clothe",
        "productname": "Striped pants combo",
        "productprice": 5999,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c35-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 136,
        "id": "c36-clothe",
        "productname": "Crop top",
        "productprice": 3500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c36-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 137,
        "id": "c37-clothe",
        "productname": "Bell sleeves crop top",
        "productprice": 3800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c37-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 138,
        "id": "c38-clothe",
        "productname": "White Body suit",
        "productprice": 3000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c38-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 101,
        "id": "c1-clothe",
        "productname": "2 piece cloth",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c1-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 102,
        "id": "c2-clothe",
        "productname": "Vintage shirt",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c2-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 103,
        "id": "c3-clothe",
        "productname": "Striped lapel",
        "productprice": 5300,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c3-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 104,
        "id": "c4-clothe",
        "productname": "Hilfigher hoodie",
        "productprice": 8000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c4-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 105,
        "id": "c5-clothe",
        "productname": "Black trouser",
        "productprice": 5900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c5-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 106,
        "id": "c6-clothe",
        "productname": "Blue lean",
        "productprice": 28000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c6-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 107,
        "id": "c7-clothe",
        "productname": "Joggers",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c7-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 108,
        "id": "c8-clothe",
        "productname": "Cargo joggers",
        "productprice": 8900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c8-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 109,
        "id": "c9-clothe",
        "productname": "El-vin GREY pack",
        "productprice": 32000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 110,
        "id": "c10-clothe",
        "productname": " Offwhite sweater",
        "productprice": 6500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 111,
        "id": "c11-clothe",
        "productname": "Baggy joggers",
        "productprice": 4900,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 112,
        "id": "c12-clothe",
        "productname": "T-shirt",
        "productprice": 4590,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 113,
        "id": "c13-clothe",
        "productname": "Coperate trouser",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 114,
        "id": "c14-clothe",
        "productname": "Striped suit trouser",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 115,
        "id": "c15-clothe",
        "productname": "Wine suit",
        "productprice": 20000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 116,
        "id": "c16-clothe",
        "productname": "Tuxedo suit",
        "productprice": 39000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 117,
        "id": "c17-clothe",
        "productname": "Diamond floral suit",
        "productprice": 27000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c17-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 118,
        "id": "c18-clothe",
        "productname": "Brown chinok",
        "productprice": 5000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c18-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 139,
        "id": "c39-clothe",
        "productname": "Baggy trouser",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/39-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
    {
        "no": 140,
        "id": "40-clothe",
        "productname": "White designer pants",
        "productprice": 3000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/40-clothe.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
    },
]