export const  searchBarData=[
    {
      id:1,
      product:'blue dior shoe'
    },
    {
      id:2,
      product:'Air jordan 4'
    },
    {
      id:3,
      product:'Adidas yeezy boost'
    },
    {
      id:4,
      product:'Female criss cross shoe'
    },
    {
      id:5,
      product:'Vans shoe'
    },
    {
      id:6,
      product:'Nike air jordan'
    },
    {
      id:7,
      product:'Nike airforce'
    },
    {
      id:8,
      product:'Puma shoe'
    },
    {
      id:9,
      product:'Louis vuitton shoe '
    },
    {
      id:10,
      product:'Brown oxford shoe'
    },
    {
      id:11,
      product:'Mens ankle boots'
    },
    {
      id:12,
      product:'Striped high boots'
    },
    {
      id:13,
      product:'Brown loafer shoe'
    },
    {
      id:14,
      product:'Stilettos leopard sandals'
    },
    {
      id:15,
      product:'High heel sandals'
    },
    {
      id:16,
      product:'Cross strap sandal'
    },
    {
      id:17,
      product:'Hollow Mesh Sandals'
    },
    {
      id:18,
      product:'Nike air jordan'
    },
    {
      id:19,
      product:'Jordan pro strong'
    },
    {
      id:20,
      product:'Adidas yeezy boost'
    },
    {
      id:21,
      product:'Channel shoe'
    },
    {
      id:22,
      product:'White channel shoe'
    },
    {
      id:23,
      product:'Madden girl shoe'
    },
    {
      id:24,
      product:'Micheal kors'
    },
    {
      id:25,
      product:'Pink zara shoe'
    },
    {
      id:26,
      product:'Bella Vita shoe'
    },
    {
      id:27,
      product:'Louis V sneakers'
    },
    {
      id:28,
      product:'Channel boots'
    },
    {
      id:29,
      product:'Red suede tie shoe'
    },
    {
      id:30,
      product:'Black seude shoe'
    },
    {
      id:31,
      product:'Brown leather shoe'
    },
    {
      id:32,
      product:'Welcome slides'
    },
    {
      id:33,
      product:'Balenciaga loafer'
    },
    {
      id:34,
      product:'Alexander mcQueen '
    },
  ]
export const shoeProductForHome=[
    {
        "no": 141,
        "id": "ci",
        "productname": "Nike airforce 1",
        "productprice": 20000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"pink nike air force 1 shoe available in size 30-48"
    },
    {
        "no": 142,
        "id": "cj",
        "productname": "blue dior shoe",
        "productprice": 35000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"blue dior shoes available in size 32-47"
    },
    {
        "no": 143,
        "id": "ck",
        "productname": "Air jordan 4",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Air jordan 4 retro kaws grey available in size 40-52"
    },
    {
        "no": 144,
        "id": "cl",
        "productname": "Adidas yeezy boost",
        "productprice": 18000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Adidas yeezy boost 350 v2 cloud white shoe"
    },
    {
        "no": 145,
        "id": "cm",
        "productname": "Female criss cross shoe",
        "productprice": 6500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Criss Cross Open Toe Flat Sliders available in size..."
    },
    {
        "no": 146,
        "id": "cn",
        "productname": "Madden girl high heels",
        "productprice": 8000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Madden girl high heels shoe available in size 27-32"
    },
    {
        "no": 147,
        "id": "co",
        "productname": "Brown toe two strap heel",
        "productprice": 11000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Brown toe two strap heel available in size 27-33"
    },
    {
        "no": 148,
        "id": "cp",
        "productname": "high sole sandals",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"brown sewade high sole sandals"
    }
]

export const menshoeProd=[
    {
        // shoes
        "no": 42,
        "id": "c1-shoe",
        "productname": "Vans shoe",
        "productprice": 14000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c1-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Vans X gremlin custom old skool shoe"
    },
    {
        "no": 43,
        "id": "c2-shoe",
        "productname": "Nike air jordan",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c2-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Nike air jordan 1 retro high sneakers"
    },
    {
        "no": 44,
        "id": "c3-shoe",
        "productname": "Nike airforce",
        "productprice": 17000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c3-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"white nike airforce 1 shoe available in size 33-47"
    },
    {
        "no": 45,
        "id": "c4-shoe",
        "productname": "Puma shoe",
        "productprice": 11000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c4-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Puma RS-Z sneakers available in size 35-48"
    },
    {
        "no": 46,
        "id": "c5-shoe",
        "productname": "Louis vuitton shoe",
        "productprice": 34000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c5-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Louis vuitton shoe available in size 33-47"
    },
    {
        "no": 14,
        "id": "c9",
        "productname": "Brown oxford shoe",
        "productprice": 15700,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High quality PU stitching clasic hollow"
    },
    {
        "no": 15,
        "id": "c10",
        "productname": "Mens ankle boots",
        "productprice": 17000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Men's Leather Chelsea Ankle Boots New Botas Hombre Shoe"
    },
    {
        "no": 16,
        "id": "c11",
        "productname": "Striped high boots",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-mshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Mens black and white striped high boots"
    },
    {
        "no": 17,
        "id": "c12",
        "productname": "Brown loafer shoe",
        "productprice": 18000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-mshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Brown loafer PU solid shoe"
    },
    {
        "no": 48,
        "id": "c6-shoe",
        "productname": "Nike air jordan",
        "productprice": 28000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c6-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Custom Nike air jordan sneakrs available in size 39-49"
    },
    {
        "no": 49,
        "id": "c7-shoe",
        "productname": "Jordan pro strong",
        "productprice": 13000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c7-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"White/Wolf Grey/Racer Blue Jordan pro strong shoe"
    },
    {
        "no": 51,
        "id": "c8-shoe",
        "productname": "Adidas yeezy boost",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c8-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Adidas yeezy boost 350 v2 glow in the dark shoe"
    },
    {
        "no": 60,
        "id": "c17-shoe",
        "productname": "Red suede tie shoe",
        "productprice": 16000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c17-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Red suede tie shoe mens shoe available in size 35-51"
    },
    {
        "no": 61,
        "id": "c18-shoe",
        "productname": "Black seude shoe",
        "productprice": 15000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c18-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black mens seude coporate shoe available in size 36-46"
    },
    {
        "no": 62,
        "id": "c19-shoe",
        "productname": "Brown leather shoe",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c19-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"brown  Oxfords Tassel Casual Genuine Leather Moccasins shoe"
    },
    {
        "no": 63,
        "id": "c20-shoe",
        "productname": "black seude shoe",
        "productprice": 13000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c20-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black mens seude coporate shoe available in size 36-46"
    },
    {
        "no": 64,
        "id": "c21-shoe",
        "productname": "Welcome slides",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c21-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"welcome slides available in size 30-46"
    },
    {
        "no": 66,
        "id": "c22-shoe",
        "productname": "Adidas yeezy slide",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c22-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"gray Adidas yeezy slide bone available in size 30-48"
    },
    {
        "no": 67,
        "id": "c23-shoe",
        "productname": "Balenciaga loafer",
        "productprice": 29000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c23-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Balenciaga loafer mule half shoe"
    },
    {
        "no": 68,
        "id": "c24-shoe",
        "productname": "Alexander mcQueen ",
        "productprice": 50000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c24-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Unisex Alexander mcQueen sneakers"
    }
]

export const womenshoeProd=[
    {
        "no": 18,
        "id": "c13",
        "productname": "Stilettos leopard sandals",
        "productprice": 7500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High heels Stilettos elastic band leopard sandals"
    },
    {
        "no": 19,
        "id": "c14",
        "productname": "High heel sandals",
        "productprice": 7800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-fshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High heel stiletto nylon boots "
    },
    {
        "no": 20,
        "id": "c15",
        "productname": "Cross strap sandal",
        "productprice": 7300,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"green color cross strap high heel sandal"
    },
    {
        "no": 21,
        "id": "c16",
        "productname": "Hollow Mesh Sandals",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Black High Heels Hollow Mesh Sandals "
    },
    {
        "no": 52,
        "id": "c9-shoe",
        "productname": "Channel shoe",
        "productprice": 32000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"White pin on channel shoe available in size 27-38"
    },
    {
        "no": 53,
        "id": "c10-shoe",
        "productname": "White channel shoe",
        "productprice": 57000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"pointed toe, pearl quilted pattern and embellished CC"
    },
    {
        "no": 54,
        "id": "c11-shoe",
        "productname": "Madden girl shoe",
        "productprice": 23000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Yellow bella two-piece block heel sandals"
    },
    {
        "no": 55,
        "id": "c12-shoe",
        "productname": "Micheal kors",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Womens imberly ankle strap dress sandals"
    },
    {
        "no": 56,
        "id": "c13-shoe",
        "productname": "Pink zara shoe",
        "productprice": 12000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Pink zara transparent shoe availablein size 28-39"
    },
    {
        "no": 57,
        "id": "c14-shoe",
        "productname": "Bella Vita shoe",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Bella Vita Womens Sarah Ii Slingback Dress Shoe Pump."
    },
    {
        "no": 58,
        "id": "c15-shoe",
        "productname": "Louis V sneakers.",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Louis vouitton sneakers available in size 26-41."
    },
    {
        "no": 59,
        "id": "c16-shoe",
        "productname": "Channel boots",
        "productprice": 22000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black high leather channel female boots "
    }    
    
]


export const shoeProd=[
    {
        "no": 42,
        "id": "c1-shoe",
        "productname": "Vans shoe",
        "productprice": 14000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c1-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Vans X gremlin custom old skool shoe"
    },
    {
        "no": 43,
        "id": "c2-shoe",
        "productname": "Nike air jordan",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c2-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Nike air jordan 1 retro high sneakers"
    },
    {
        "no": 44,
        "id": "c3-shoe",
        "productname": "Nike airforce",
        "productprice": 17000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c3-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"white nike airforce 1 shoe available in size 33-47"
    },
    {
        "no": 45,
        "id": "c4-shoe",
        "productname": "Puma shoe",
        "productprice": 11000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c4-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Puma RS-Z sneakers available in size 35-48"
    },
    {
        "no": 46,
        "id": "c5-shoe",
        "productname": "Louis vuitton shoe",
        "productprice": 34000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c5-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Louis vuitton shoe available in size 33-47"
    },
    {
        "no": 14,
        "id": "c9",
        "productname": "Brown oxford shoe",
        "productprice": 15700,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High quality PU stitching clasic hollow"
    },
    {
        "no": 15,
        "id": "c10",
        "productname": "Mens ankle boots",
        "productprice": 17000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Men's Leather Chelsea Ankle Boots New Botas Hombre Shoe"
    },
    {
        "no": 16,
        "id": "c11",
        "productname": "Striped high boots",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-mshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Mens black and white striped high boots"
    },
    {
        "no": 17,
        "id": "c12",
        "productname": "Brown loafer shoe",
        "productprice": 18000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-mshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Brown loafer PU solid shoe"
    },
    {
        "no": 18,
        "id": "c13",
        "productname": "Stilettos leopard sandals",
        "productprice": 7500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High heels Stilettos elastic band leopard sandals"
    },
    {
        "no": 19,
        "id": "c14",
        "productname": "High heel sandals",
        "productprice": 7800,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-fshoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"High heel stiletto nylon boots "
    },
    {
        "no": 20,
        "id": "c15",
        "productname": "Cross strap sandal",
        "productprice": 7300,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"green color cross strap high heel sandal"
    },
    {
        "no": 21,
        "id": "c16",
        "productname": "Hollow Mesh Sandals",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Black High Heels Hollow Mesh Sandals "
    },
    {
        "no": 48,
        "id": "c6-shoe",
        "productname": "Nike air jordan",
        "productprice": 28000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c6-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Custom Nike air jordan sneakrs available in size 39-49"
    },
    {
        "no": 49,
        "id": "c7-shoe",
        "productname": "Jordan pro strong",
        "productprice": 13000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c7-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"White/Wolf Grey/Racer Blue Jordan pro strong shoe"
    },
    {
        "no": 51,
        "id": "c8-shoe",
        "productname": "Adidas yeezy boost",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c8-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Adidas yeezy boost 350 v2 glow in the dark shoe"
    },
    {
        "no": 52,
        "id": "c9-shoe",
        "productname": "Channel shoe",
        "productprice": 32000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"White pin on channel shoe available in size 27-38"
    },
    {
        "no": 53,
        "id": "c10-shoe",
        "productname": "White channel shoe",
        "productprice": 57000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"pointed toe, pearl quilted pattern and embellished CC"
    },
    {
        "no": 54,
        "id": "c11-shoe",
        "productname": "Madden girl shoe",
        "productprice": 23000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Yellow bella two-piece block heel sandals"
    },
    {
        "no": 55,
        "id": "c12-shoe",
        "productname": "Micheal kors",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Womens imberly ankle strap dress sandals"
    },
    {
        "no": 56,
        "id": "c13-shoe",
        "productname": "Pink zara shoe",
        "productprice": 12000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Pink zara transparent shoe availablein size 28-39"
    },
    {
        "no": 57,
        "id": "c14-shoe",
        "productname": "Bella Vita shoe",
        "productprice": 6000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Bella Vita Womens Sarah Ii Slingback Dress Shoe Pump."
    },
    {
        "no": 58,
        "id": "c15-shoe",
        "productname": "Louis V sneakers.",
        "productprice": 19000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Louis vouitton sneakers available in size 26-41."
    },
    {
        "no": 59,
        "id": "c16-shoe",
        "productname": "Channel boots",
        "productprice": 22000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img (2).png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black high leather channel female boots "
    },
    {
        "no": 60,
        "id": "c17-shoe",
        "productname": "Red suede tie shoe",
        "productprice": 16000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c17-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Red suede tie shoe mens shoe available in size 35-51"
    },
    {
        "no": 61,
        "id": "c18-shoe",
        "productname": "Black seude shoe",
        "productprice": 15000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c18-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black mens seude coporate shoe available in size 36-46"
    },
    {
        "no": 62,
        "id": "c19-shoe",
        "productname": "Brown leather shoe",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c19-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"brown  Oxfords Tassel Casual Genuine Leather Moccasins shoe"
    },
    {
        "no": 63,
        "id": "c20-shoe",
        "productname": "black seude shoe",
        "productprice": 13000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c20-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"black mens seude coporate shoe available in size 36-46"
    },
    {
        "no": 64,
        "id": "c21-shoe",
        "productname": "Welcome slides",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c21-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"welcome slides available in size 30-46"
    },
    {
        "no": 66,
        "id": "c22-shoe",
        "productname": "Adidas yeezy slide",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c22-shoe-img.jpeg",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"gray Adidas yeezy slide bone available in size 30-48"
    },
    {
        "no": 67,
        "id": "c23-shoe",
        "productname": "Balenciaga loafer",
        "productprice": 29000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c23-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Balenciaga loafer mule half shoe"
    },
    {
        "no": 68,
        "id": "c24-shoe",
        "productname": "Alexander mcQueen ",
        "productprice": 50000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c24-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Unisex Alexander mcQueen sneakers"
    },{
        "no": 145,
        "id": "cm",
        "productname": "Female criss cross shoe",
        "productprice": 6500,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c13-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Criss Cross Open Toe Flat Sliders available in size..."
    },
    {
        "no": 146,
        "id": "cn",
        "productname": "Madden girl high heels",
        "productprice": 8000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c14-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Madden girl high heels shoe available in size 27-32"
    },
    {
        "no": 147,
        "id": "co",
        "productname": "Brown toe two strap heel",
        "productprice": 11000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c15-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Brown toe two strap heel available in size 27-33"
    },
    {
        "no": 148,
        "id": "cp",
        "productname": "high sole sandals",
        "productprice": 9000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c16-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"brown sewade high sole sandals"
    },
    {
        "no": 141,
        "id": "ci",
        "productname": "Nike airforce 1",
        "productprice": 20000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c9-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"pink nike air force 1 shoe available in size 30-48"
    },
    {
        "no": 142,
        "id": "cj",
        "productname": "blue dior shoe",
        "productprice": 35000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c10-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"blue dior shoes available in size 32-47"
    },
    {
        "no": 143,
        "id": "ck",
        "productname": "Air jordan 4",
        "productprice": 25000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c11-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Air jordan 4 retro kaws grey available in size 40-52"
    },
    {
        "no": 144,
        "id": "cl",
        "productname": "Adidas yeezy boost",
        "productprice": 18000,
        "quantity":1,
        "productinfo": null,
        "productimage1": "/skydome images/c12-shoe-img.png",
        "productimage2": null,
        "processortype": null,
        "processorname": null,
        "brand": null,
        "batteryinfo": null,
        "ram": null,
        "rom": null,
        "windowversion": null,
        "color": null,
        "touchscreen": null,
        "netweight": null,
        "inch": null,
        "gaminggraphics": null,
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoe":true,
        "shoesize": 31,
        "productinfopreview":"Adidas yeezy boost 350 v2 cloud white shoe"
    }
]