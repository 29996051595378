export let sliderImg=[
    {
      id:1,
      src:'/skydome images/Slide-img1.jpg',
    },
    {
      id:2,
      src:'/skydome images/Slide-img2.jpg',
    },
    {
      id:3,
      src:'/skydome images/Slide-img3.jpg',
    },
    {
      id:4,
      src:'/skydome images/Slide-img4.jpeg',
    },
    {
      id:5,
      src:'/skydome images/Slide-img5.jpeg',
    },
    {
      id:6,
      src:'/skydome images/Slide-img6.jpeg',
    },
  ] 