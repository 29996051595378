
export const allProductDb =[
        {
            "no": 1,
            "id": "ca",
            "productname": "hp 250 g8",
            "productprice": 325000,
            "productinfo": "hp 250 g8 10th generation laptop",
            "productimage1": "/skydome images/hp-250-g8-img1.jpeg",
            "productimage2": "/skydome images/hp-250-g8-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "quantity":1,
            "brand": "hp",
            "batteryinfo": "45watt li-on battery",
            "ram": "8gb",
            "rom": "1tbhdd",
            "windowversion": "windows 10 pro",
            "color": "silver",
            "touchscreen": "no",
            "netweight": "1.3kg",
            "inch": "15",
            "gaminggraphics": "2gb dedicated gaming graphics",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"hp 250 g8 laptop 8gb ram 1tb hdd windows 10pro core i5"
        
        },
        {
            "no": 3,
            "id": "cb",
            "productname": "lenovo legion 5",
            "productprice": 650000,
            "productinfo": "lenovo legion 5 15IMH05 10th generation laptop",
            "productimage1": "/skydome images/lenovo-legion-5-img1.png",
            "productimage2": "/skydome images/lenovo-legion-5-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "quantity":1,
            "brand": "lenovo",
            "batteryinfo": "integrated 60Wh",
            "ram": "8gb",
            "rom": "512gb ssd",
            "windowversion": "windows 10 home",
            "color": "black",
            "touchscreen": "no",
            "netweight": "1.3kg",
            "inch": "15.6",
            "gaminggraphics": "NVIDIA GTX 4GB GDDR6",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"lenovo legion 5 core i5 8gb ram 512gb 4gb nivida win..."
        },
        {
            "no": 4,
            "id": "cc",
            "productname": "mac book pro",
            "productprice": 1395000,
            "productinfo": "apple mac book pro 16\" AMD Raedon Pro 5500M",
            "productimage1": "/skydome images/mac-book-pro-img2.jpeg",
            "productimage2": "/skydome images/mac-book-pro-img1.jpeg",
            "processortype": "intel",
            "processorname": "corei9",
            "quantity":1,
            "brand": "Apple",
            "batteryinfo": "96W USB-C power adapter Up to 11 hours battery life",
            "ram": "16gb",
            "rom": "1tb ssd",
            "windowversion": "mac os",
            "color": "space grey",
            "touchscreen": "no",
            "netweight": "2kg",
            "inch": "14.09",
            "gaminggraphics": "AMD Radeon Pro 5500M graphics card With 4GB of GDDR6 VRAM",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"apple mac book pro touch bar core i9 16gb 1tb ssd AMD..."
        },
        {
            "no": 6,
            "id": "cd",
            "productname": "acer predator helios",
            "productprice": 810000,
            "productinfo": "acer predator helios 300",
            "productimage1": "/skydome images/acer-predator-helios-img2.jpeg",
            "productimage2": "/skydome images/acer-predator-helios-img1.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "quantity":1,
            "brand": "Acer",
            "batteryinfo": "Average Battery Life (in hours) ?6 Hours",
            "ram": "16gb",
            "rom": "512gb ssd",
            "windowversion": "windows 10",
            "color": "black",
            "touchscreen": "no",
            "netweight": "2.5kg",
            "inch": "15.6",
            "gaminggraphics": "Dedicated NVIDIA GeForce RTX 3060 6GB",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"acer predator helios 300 corei7 16gb ram,..."
        },
        {
            "no": 69,
            "id": "c1-laptop",
            "productname": " DELL XPS 17",
            "productprice": 1555000,
            "quantity":1,
            "productinfo": "DELL XPS 17 9700 10th generation laptop",
            "productimage1": "/skydome images/c1-laptop-img.jpeg",
            "productimage2": "/skydome images/c1-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "core19",
            "brand": "DELL",
            "batteryinfo": "Primary Battery 6-Cell Battery, 97WHr (Integrated)",
            "ram": "32",
            "rom": "1tb ssd",
            "windowversion": "windows 10 pro",
            "color": "silver",
            "touchscreen": "no",
            "netweight": "2.51kg",
            "inch": "17.0",
            "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6 with Max-Q",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Dell xps 32gb ram 1tb ssd,windows 10, core 19..."
        },
        {
            "no": 71,
            "id": "c2-laptop",
            "productname": "DELL G5 Gamming pc",
            "productprice": 460000,
            "quantity":1,
            "productinfo": "DELL G5 Gamming 10th generation laptop",
            "productimage1": "/skydome images/c2-laptop-img1.jpeg",
            "productimage2": "/skydome images/c2-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "core15",
            "brand": "DELL",
            "batteryinfo": "Li-polymer 15.2v 68Wh 4-cell battery",
            "ram": "8",
            "rom": "256 ssd",
            "windowversion": "windows 10 pro",
            "color": "black",
            "touchscreen": "no",
            "netweight": "2.8kg",
            "inch": "15.6",
            "gaminggraphics": "4gb ram NVIDIA GeForce GTX 1650Ti",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Dell G5 gamming laptop 8gb ram,256gb ssd,windows 10h"
        },
        {
            "no": 72,
            "id": "c3-laptop",
            "productname": "Asus X515JA",
            "productprice": 214000,
            "quantity":1,
            "productinfo": "Asus X515JA laptop",
            "productimage1": "/skydome images/c3-laptop-img1.jpeg",
            "productimage2": "/skydome images/c3-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "core13",
            "brand": "ASUS",
            "batteryinfo": "Lithium Battery Energy Content ?37 Watt Hours 2 ion cells",
            "ram": "4",
            "rom": "1tb ssd",
            "windowversion": "windows 10",
            "color": "Transparent silver",
            "touchscreen": "no",
            "netweight": "1.7kg",
            "inch": "15.6",
            "gaminggraphics": "intel graphics card",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Asus x515ja ,4gb ram,1tb hdd,windows 10,core i3"
        },
        {
            "no": 153,
            "id": "c1",
            "productname": "DELL latitude 3310",
            "productprice": 265000,
            "quantity":1,
            "productinfo": "DELL latitude 3310 core i3 256GB SSD..",
            "productimage1": "/skydome images/dell-latitude-img1.jpeg",
            "productimage2": "/skydome images/dell-latitude-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei3",
            "brand": "dell",
            "batteryinfo": "Polymer 3c 42 Watts battery with 11.40 VDC voltage",
            "ram": "8gb",
            "rom": "256GB",
            "windowversion": "windows 10 pro(64-bit)",
            "color": "black",
            "touchscreen": "no",
            "netweight": "1.17kg",
            "inch": "13.3",
            "gaminggraphics": "intel UHD graphics 620",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"DELL latitude 3310 core i3 256GB SSD.."
        
        },
        {
            "no": 154,
            "id": "c2",
            "productname": "Acer nitro 5",
            "productprice": 770000,
            "quantity":1,
            "productinfo": "9th generation intel core i7 -9750H gamming laptop with backlit keybord",
            "productimage1": "/skydome images/acer-nitro-5-img1.jpeg",
            "productimage2": "/skydome images/acer-nitro-5-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "acer",
            "batteryinfo": "4 cell 57.5 Wh Lithium-Ion battery",
            "ram": "16GB DDR4 2666MHz",
            "rom": "256gb ssd",
            "windowversion": "windows 10 home",
            "color": "black",
            "touchscreen": "no",
            "netweight": "3kg",
            "inch": "15.6",
            "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"acer nitro 5 core i7 16gb ram 512gb 4gb nivida win..."
        },
        {
            "no": 155,
            "id": "c3",
            "productname": "Hp omen 16",
            "productprice": 1250000,
            "quantity":1,
            "productinfo": "Hp omen 16 (60H77EA)",
            "productimage1": "/skydome images/hp-omen-img1.jpeg",
            "productimage2": "/skydome images/hp-omen-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "hp",
            "batteryinfo": "6-cell 83Wh Li-ion polymer",
            "ram": "32gb",
            "rom": "1tb ssd",
            "windowversion": "Windows 11 Home",
            "color": "black",
            "touchscreen": "no",
            "netweight": "2kg",
            "inch": "16.1",
            "gaminggraphics": "NVIDIA GeForce RTX 3070 8GB GDDR6",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Hp omen 16 gaming 11th Gen Core i7..."
        },
        {
            "no": 156,
            "id": "c4",
            "productname": "Asus ROG ZEPHYRUS G1",
            "productprice": 2000000,
            "quantity":1,
            "productinfo": "Asus ROG ZEPHYRUS G1 AMD RYZEN 9-5900H",
            "productimage1": "/skydome images/Asus ROG-img1.jpeg",
            "productimage2": "/skydome images/Asus ROG-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "Asus",
            "batteryinfo": "1 Lithium ion batterie with 20Volts",
            "ram": "40gb DDR4",
            "rom": "2TB ssd",
            "windowversion": "windows 10",
            "color": "Off black",
            "touchscreen": "no",
            "netweight": "2.60kg",
            "inch": "15.51",
            "gaminggraphics": "8GB NVIDIA GeForce RTX 3070",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Asus ROG ZEPHYRUS G1, AMD RYZEN 9-5900H,2TB SSD/40GB..."
        },
        {
            "no": 73,
            "id": "c4-laptop",
            "productname": "Apple macbook air",
            "productprice": 590000,
            "quantity":1,
            "productinfo": "Apple macbook air laptop with M1 chip",
            "productimage1": "/skydome images/c4-laptop-img1.jpeg",
            "productimage2": "/skydome images/c4-laptop-img2.jpeg",
            "processortype": "Apple M1",
            "processorname": "corei5",
            "brand": "Apple",
            "batteryinfo": "30W USB Type-C Power Adapter",
            "ram": "8gb",
            "rom": "256 ssd",
            "windowversion": "macOS Big Sur 11.0",
            "color": "space grey",
            "touchscreen": "no",
            "netweight": "0.7kg",
            "inch": "13",
            "gaminggraphics": "2gb ram",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Apple macbook air 13' M1 chip core 15 8gb ram 256gb rom"
        },
        {
            "no": 74,
            "id": "c5-laptop",
            "productname": "Razer blade Stealth",
            "productprice": 682000,
            "quantity":1,
            "productinfo": "Razer Blade Stealth 13 GAMING laptop",
            "productimage1": "/skydome images/c5-laptop-img1.jpeg",
            "productimage2": "/skydome images/c5-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "Razer",
            "batteryinfo": "53.1Wh battery with up to 10hours battery life",
            "ram": "16gb",
            "rom": "256gb ssd",
            "windowversion": "windows 10",
            "color": "black",
            "touchscreen": "no",
            "netweight": "2.9kg",
            "inch": "13.3",
            "gaminggraphics": "NVIDIA GeForce GTX MX150 graphics card.",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Razer Blade Stealth 13 GAMING laptop,core i7,16gb ram 256.."
        },
        {
            "no": 75,
            "id": "c6-laptop",
            "productname": "Lenovo v15",
            "productprice": 258000,
            "quantity":1,
            "productinfo": "Lenovo v15 laptop",
            "productimage1": "/skydome images/c6-laptop-img1.jpeg",
            "productimage2": "/skydome images/c6-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei3",
            "brand": "Lenovo",
            "batteryinfo": "4 Lithium Ion Cells with up to 8hours",
            "ram": "26gb",
            "rom": "1tb hdd",
            "windowversion": "windows 10",
            "color": "black",
            "touchscreen": "no",
            "netweight": "1.1kg",
            "inch": "15.6",
            "gaminggraphics": "Intel HD Graphics 610",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Lenovo V15 core i3 ,12gb ram,1tb hdd,windows 10..."
        },
        {
            "no": 76,
            "id": "c7-laptop",
            "productname": "Microsoft surface pro 6",
            "productprice": 645000,
            "quantity":1,
            "productinfo": "Microsoft surface pro 6 laptop",
            "productimage1": "/skydome images/c7-laptop-img1.jpeg",
            "productimage2": "/skydome images/c7-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "Microsoft",
            "batteryinfo": "up to 6hours",
            "ram": "16gb",
            "rom": "512 ssd",
            "windowversion": "windows 10",
            "color": "platinum",
            "touchscreen": "no",
            "netweight": "0.77kg",
            "inch": "12.3",
            "gaminggraphics": "Intel HD Graphics ",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Microsoft Surface Pro 6 12.3' Intel Corei7 512GB/16GB..."
        },
        {
            "no": 77,
            "id": "c8-laptop",
            "productname": "Hp 240 G8 ",
            "productprice": 280000,
            "quantity":1,
            "productinfo": "Hp 240 G8 laptop",
            "productimage1": "/skydome images/c8-laptop-img1.jpeg",
            "productimage2": "/skydome images/c8-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei3",
            "brand": "HP",
            "batteryinfo": "PowerAC adapter power45 W",
            "ram": "12gb",
            "rom": "1tb hdd",
            "windowversion": "windows 10 pro",
            "color": "Black",
            "touchscreen": "no",
            "netweight": "1.47kg",
            "inch": "12.8",
            "gaminggraphics": "Intel HD Graphics ",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Hp 240 G8 Intel Core I3 10th Gen 1TB HDD 12GB RAM WIN 10.."
        },
        {
            "no": 78,
            "id": "c9-laptop",
            "productname": "Asus gaming pc",
            "productprice": 690000,
            "quantity":1,
            "productinfo": "Asus ROG STRIX gaming laptop",
            "productimage1": "/skydome images/c9-laptop-img1.jpeg",
            "productimage2": "/skydome images/c9-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "Asus",
            "batteryinfo": "1 Lithium ion battery",
            "ram": "16gb",
            "rom": "1tb ssd",
            "windowversion": "windows 10 home",
            "color": "Black",
            "touchscreen": "no",
            "netweight": "1.47kg",
            "inch": "15.6",
            "gaminggraphics": "NVIDIA GeForce GTX 1050 4GB graphics. Dual-fan thermal design",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Asus ROG STRIX Gaming Laptop 15.6” Core I7-7700HQ 16GB..."
        },
        {
            "no": 79,
            "id": "c10-laptop",
            "productname": "DELL ALIENWARE",
            "productprice": 1100000,
            "quantity":1,
            "productinfo": "DELL ALIENWARE M15 R3 9th generation laptop",
            "productimage1": "/skydome images/c10-laptop-img.png",
            "productimage2": "/skydome images/c10-laptop-img2.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "DELL",
            "batteryinfo": "1 Lithium ion battery",
            "ram": "16gb",
            "rom": "512gb ssd",
            "windowversion": "windows 10 home",
            "color": "Black",
            "touchscreen": "no",
            "netweight": "4.7kg",
            "inch": "15.6",
            "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"DELL ALIENWARE M15 R3 - INTEL CORE I7-10750H, 15.6..."
        },
        {
            "no": 80,
            "id": "c11-laptop",
            "productname": "infinix INbook",
            "productprice": 470000,
            "quantity":1,
            "productinfo": "infinix INbook laptop",
            "productimage1": "/skydome images/c11-laptop-img2.jpeg",
            "productimage2": "/skydome images/c11-laptop-img1.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "INFINIX",
            "batteryinfo": " 55Wh battery Up to 13 Hours of Web Browsing",
            "ram": "16gb",
            "rom": "512gb ssd",
            "windowversion": "windows 10 home",
            "color": "Grey",
            "touchscreen": "no",
            "netweight": "1.4kg",
            "inch": "14",
            "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Infinix INBook Intel Core I7, 16GB RAM/512GB Ssd X1 Pro..."
        },
        {
            "no": 81,
            "id": "c12-laptop",
            "productname": "Hp Pavilion 15",
            "productprice": 495000,
            "quantity":1,
            "productinfo": "Pavilion 15 CONVERTIBLE laptop",
            "productimage1": "/skydome images/c12-laptop-img2.jpeg",
            "productimage2": "/skydome images/c12-laptop-img1.jpeg",
            "processortype": "intel",
            "processorname": "corei7",
            "brand": "HP",
            "batteryinfo": "41.5Wh 3 cell Li-polymer battery",
            "ram": "8gb",
            "rom": "256gb ssd",
            "windowversion": "windows 10 home",
            "color": "Black and silver",
            "touchscreen": "Yes",
            "netweight": "1.32kg",
            "inch": "15.6",
            "gaminggraphics": "intel graphics",
            "network": null,
            "frontcamera": null,
            "rearcamera": null,
            "shoesize": null,
            "productinfopreview":"Pavilion 15 CONVERTIBLE,touch screen,corei7,8 generation.."
        },
            {
                "no": 149,
                "id": "c5",
                "productname": "Iphone 12 pro max",
                "productprice": 630000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/iphone-12promax-img2.jpeg",
                "productimage2": "/skydome images/iphone-12promax-img1.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "3687mAh",
                "ram": "6gb",
                "rom": "256gb",
                "windowversion": "iOS 14.1",
                "color": "graphite",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.7",
                "gaminggraphics": null,
                "network": "5G",
                "frontcamera": "12MP",
                "rearcamera": "(12MP+12MP+12MP)",
                "shoesize": null,
                "productinfopreview":"Iphone 12 pro max 3687mAh 6gb ram ,256gb rom graphite.. "
        
            },
            {
                "no": 150,
                "id": "c6",
                "productname": "samsung galaxy s21 ultra",
                "productprice": 619000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/samsung-galaxy-s21-img2.jpeg",
                "productimage2": "/skydome images/samsung-galaxy-s21-img1.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "5000mAh",
                "ram": "12gb",
                "rom": "256gb",
                "windowversion": "android version 11 with one ui3.1",
                "color": "black",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.6",
                "gaminggraphics": null,
                "network": "5G",
                "frontcamera": "40MP",
                "rearcamera": "12MP Ultra Wide Camera, 108MP Wide-angle Camera, Double 10MP Telephoto Camera",
                "shoesize": null,
                "productinfopreview":"samsung galaxy s21 ultra 5000mAh 12gb ram 256gb... "
        
            },
            {
                "no": 151,
                "id": "c7",
                "productname": "samsung galaxy M51",
                "productprice": 195000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/samsung-galaxy-m51-img2.jpeg",
                "productimage2": "/skydome images/samsung-galaxy-m51-img1.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "7000mAh",
                "ram": "8gb",
                "rom": "128gb",
                "windowversion": "android version 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.7",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "32MP",
                "rearcamera": "(64MP+12MP+5MP+5MP)",
                "shoesize": null,
                "productinfopreview":"samsung galaxy M51 android version 10 8gb ram 128gb..."
        
            },
            {
                "no": 152,
                "id": "c8",
                "productname": "infinix s5 pro",
                "productprice": 87000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/infinix-s5-pro-img2.jpeg",
                "productimage2": "/skydome images/infinix-s5-pro-img1.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4000mAh",
                "ram": "6gb",
                "rom": "128gb",
                "windowversion": "android version 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.53",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "40MP",
                "rearcamera": "48MP/2MP/QVGA",
                "shoesize": null,
                "productinfopreview":"infinix s5 pro 4000mAh 6gb ram 128gb rom android ve..."
        
            },
            {
                "no": 82,
                "id": "c1-phone",
                "productname": "Tecno camon 17",
                "productprice": 87000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c1-phone-img1.jpeg",
                "productimage2": "/skydome images/c1-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "5000mAh",
                "ram": "4gb",
                "rom": "128gb",
                "windowversion": "android version 11",
                "color": "Frost silver",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.6",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "8MP",
                "rearcamera": "48MP triple rear",
                "shoesize": null,
                "productinfopreview":"Tecno camon 17 (CG6) 6.6' 90Hz HD+,4GB ram 128gb rom..."
            },
            {
                "no": 83,
                "id": "c2-phone",
                "productname": "Black view oscal pad 8",
                "productprice": 69000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c2-phone-img1.jpeg",
                "productimage2": "/skydome images/c2-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "6580mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "android version 11",
                "color": "Tablet grey",
                "touchscreen": null,
                "netweight": null,
                "inch": "10.1",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "2MP",
                "rearcamera": "4MP triple rear",
                "shoesize": null,
                "productinfopreview":"Blackview Oscal Pad 8 10.1' 4GB+64GB Android 11 4G..."
            },
            {
                "no": 84,
                "id": "c3-phone",
                "productname": "Gionee M12",
                "productprice": 84000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c3-phone-img1.jpeg",
                "productimage2": "/skydome images/c3-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "5100mAh",
                "ram": "6gb",
                "rom": "128gb",
                "windowversion": "android version 10",
                "color": "Tablet grey",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.55",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "16MP",
                "rearcamera": " Quad Camera: 48MP + 5MP + 2MP + 2MP",
                "shoesize": null,
                "productinfopreview":"Gionee M12, 6.55-Inch HD+ (6GB RAM, 128GB ROM) Androi.."
            },
            {
                "no": 85,
                "id": "c4-phone",
                "productname": "Huawei y9a",
                "productprice": 157000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c4-phone-img1.jpeg",
                "productimage2": "/skydome images/c4-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4300mAh",
                "ram": "8gb",
                "rom": "128gb",
                "windowversion": "android version 10",
                "color": "Saruka pink",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.63",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "16MP",
                "rearcamera": "64MP + 8MP + 2MP + 2MP",
                "shoesize": null,
                "productinfopreview":"Huawei Y9a, 6.63-Inch (8GB RAM + 128GB ROM) Android..."
            },
            {
                "no": 47,
                "id": "cq",
                "productname": "XIAOMI poco m4 pro",
                "productprice": 95000,
                "productinfo": null,
                "productimage1": "/skydome images/XIAOMI-img1.jpeg",
                "productimage2": "/skydome images/XIAOMI-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "quantity":1,
                "batteryinfo": "5000mAh",
                "ram": "6gb",
                "rom": "128gb",
                "windowversion": "android version 11",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.42",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "16MP",
                "rearcamera": "64MP Triple camera",
                "shoesize": null,
                "productinfopreview":"XIAOMI poco m4 pro AMOLED Screen 6gb/126gb memory..."
            },
            {
                "no": 9,
                "id": "cr",
                "productname": "Tecno POVA Neo",
                "productprice": 76000,
                "productinfo": null,
                "productimage1": "/skydome images/Tecno POVA Neo-img1.jpeg",
                "productimage2": "/skydome images/Tecno POVA Neo-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "quantity":1,
                "batteryinfo": "6000mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "Android 11",
                "color": "blaack",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.8",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "12MP",
                "rearcamera": "13MP Dual rear with quad flash",
                "shoesize": null,
                "productinfopreview":"Tecno POVA Neo (LE6) 6.8'' HD + 4GB RAM + 64GB ROM,6000..."
            },
            {
                "no": 10,
                "id": "cs",
                "productname": "Samsung Galaxy A23",
                "productprice": 150000,
                "productinfo": null,
                "productimage1": "/skydome images/samsung-galaxy-a23-img1.jpeg",
                "productimage2": "/skydome images/samsung-galaxy-a23-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "quantity":1,
                "batteryinfo": "5000mAh",
                "ram": "4gb",
                "rom": "128gb",
                "windowversion": "android version 12 ",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.6",
                "gaminggraphics": null,
                "network": "5G/4G/3G/2G",
                "frontcamera": "8MP",
                "rearcamera": "50MP quad camera",
                "shoesize": null,
                "productinfopreview":"samsung galaxy a23 6.6 inch TFTscreen 4/128gbram memory"
            },
            {
                "no": 12,
                "id": "ct",
                "productname": "Infinix note 11 pro",
                "productprice": 148000,
                "productinfo": null,
                "productimage1": "/skydome images/Infinix note 11 pro-img1.jpeg",
                "productimage2": "/skydome images/Infinix note 11 pro-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "quantity":1,
                "batteryinfo": "5000mAh",
                "ram": "8gb",
                "rom": "128gb",
                "windowversion": "android version 11",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.95",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "16MP ff Selfie with Dual flash",
                "rearcamera": "64MP 30X Telescope zoom",
                "shoesize": null,
                "productinfopreview":"infinix note 11 pro 5000mah 8gb ram 128gb rom,android 11"
            },
            {
                "no": 86,
                "id": "c5-phone",
                "productname": "Huawei p8 max",
                "productprice": 65000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c5-phone-img1.jpeg",
                "productimage2": "/skydome images/c5-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4360mAh",
                "ram": "3gb",
                "rom": "32gb",
                "windowversion": "android version 5.0 + emotion UI 3.1",
                "color": "silver",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.8",
                "gaminggraphics": null,
                "network": "4G-LTE",
                "frontcamera": "8MP",
                "rearcamera": "32MP",
                "shoesize": null,
                "productinfopreview":"Huawei P8 Max 6.8' Inch 4G LTE Android IPS 3gb ram..."
            },
            {
                "no": 87,
                "id": "c6-phone",
                "productname": "Tecno phantom X",
                "productprice": 249000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c6-phone-img1.jpeg",
                "productimage2": "/skydome images/c6-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4700mAh",
                "ram": "8gb",
                "rom": "256gb",
                "windowversion": "android version 11",
                "color": "sunset",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.7",
                "gaminggraphics": null,
                "network": "4G-LTE",
                "frontcamera": "48MP + 8MP",
                "rearcamera": "50MP+13MP+8MP",
                "shoesize": null,
                "productinfopreview":"Tecno Phantom X 6.7-Inch, 256GB ROM,8GB RAM Android..."
            },
            {
                "no": 88,
                "id": "c7-phone",
                "productname": "Iphone 12",
                "productprice": 427000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c7-phone-img1.jpeg",
                "productimage2": "/skydome images/c7-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "2815mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "ios 14.1",
                "color": "Blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.1",
                "gaminggraphics": null,
                "network": "5G",
                "frontcamera": "48MP + 12MP",
                "rearcamera": "12MP+12MP",
                "shoesize": null,
                "productinfopreview":"Apple Iphone 12 6.1 Inches-4GB RAM, 64GB ROM, 2815mAh,5G"
            },
            {
                "no": 89,
                "id": "c8-phone",
                "productname": "samsung galaxy s10 plus",
                "productprice": 220000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c8-phone-img1.jpeg",
                "productimage2": "/skydome images/c8-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4100mAh",
                "ram": "8gb",
                "rom": "128gb",
                "windowversion": "android 9.0",
                "color": "white",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.4",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "8MP + 12MP",
                "rearcamera": "12MP+16MP",
                "shoesize": null,
                "productinfopreview":"Samsung Galaxy S10 Plus (S10+) 6.4-Inch AMOLED..."
            },
            {
                "no": 90,
                "id": "c9-phone",
                "productname": "XIAOMI redmi note 9s",
                "productprice": 130000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c9-phone-img1.jpeg",
                "productimage2": "/skydome images/c9-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "5020mAh",
                "ram": "6gb",
                "rom": "128gb",
                "windowversion": "MIUI",
                "color": "Auorora blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.67",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "16MP",
                "rearcamera": "48MP+8MP+5MP+2MP",
                "shoesize": null,
                "productinfopreview":"XIAOMI Redmi Note 9s 6GB 128GB, 48MP+8MP+5MP+2MP..."
            },
            {
                "no": 91,
                "id": "c10-phone",
                "productname": "Nokia 8.3",
                "productprice": 225000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c10-phone-img1.jpeg",
                "productimage2": "/skydome images/c10-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4500mAh",
                "ram": "8gb",
                "rom": "128gb",
                "windowversion": "android 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.81",
                "gaminggraphics": null,
                "network": "5G",
                "frontcamera": "24MP",
                "rearcamera": "64MP+12MP+2MP+2MP",
                "shoesize": null,
                "productinfopreview":"Nokia 8.3 8/128 GB, 6.81' FHD+, Dual SIM, 64/12/2/2MP.."
            },
            {
                "no": 92,
                "id": "c11-phone",
                "productname": "Ouikitel c21",
                "productprice": 57800,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c11-phone-img1.jpeg",
                "productimage2": "/skydome images/c11-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4000mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "android 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.4",
                "gaminggraphics": null,
                "network": "4G",
                "frontcamera": "20MP AI selfie",
                "rearcamera": "16MP Matrix Quad Camera",
                "shoesize": null,
                "productinfopreview":"Oukitel C21 4G 6.4” FHD ( 64GB ROM 4GB RAM) 20 MP AI..."
            },
            {
                "no": 93,
                "id": "c12-phone",
                "productname": "Samsung galaxy A12",
                "productprice": 87500,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c12-phone-img1.jpeg",
                "productimage2": "/skydome images/c12-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "5000mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "android 10",
                "color": "black",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.5",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "8MP",
                "rearcamera": "48MP+5MP+5MP+2MP",
                "shoesize": null,
                "productinfopreview":"Samsung Galaxy A12(A125F/DS)- 6.5' 48MP Camera 4/64gb ..."
            },
            {
                "no": 94,
                "id": "c13-phone",
                "productname": "Itel A35",
                "productprice": 26000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c13-phone-img1.jpeg",
                "productimage2": "/skydome images/c13-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "3020mAh",
                "ram": "1gb",
                "rom": "16gb",
                "windowversion": "android 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "5.0",
                "gaminggraphics": null,
                "network": "3G",
                "frontcamera": "8MP",
                "rearcamera": "2MP",
                "shoesize": null,
                "productinfopreview":"Itel A35 5.0' HD Screen, 1GB RAM + 16GB ROM, Android 10..."
            },
            {
                "no": 95,
                "id": "c14-phone",
                "productname": "Gionee P15 Pro",
                "productprice": 64900,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c14-phone-img1.jpeg",
                "productimage2": "/skydome images/c14-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "6000mAh",
                "ram": "3gb",
                "rom": "64gb",
                "windowversion": "android 11",
                "color": "Fjord",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.8",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "8MP",
                "rearcamera": "13MP+2MP+0.1MPdepth with LED flash",
                "shoesize": null,
                "productinfopreview":"Gionee P15 PRO-6.82'HD+ V-NOTCH DISPLAY-64GB ROM/3GB..."
            },
            {
                "no": 98,
                "id": "c15-phone",
                "productname": "Apple ipad",
                "productprice": 217000,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c15-phone-img1.jpeg",
                "productimage2": "/skydome images/c15-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4000mAh",
                "ram": "3gb",
                "rom": "32gb",
                "windowversion": "IOS",
                "color": "Space grey",
                "touchscreen": null,
                "netweight": null,
                "inch": "10.2",
                "gaminggraphics": null,
                "network": "Wifi",
                "frontcamera": "2MP",
                "rearcamera": "5MP",
                "shoesize": null,
                "productinfopreview":"Apple Ipad 7th (2019) 10.2 Inches,3GB+32GB,Space Gray..."
            },
            {
                "no": 99,
                "id": "c16-phone",
                "productname": "Gionee s12",
                "productprice": 58900,
                "quantity":1,
                "productinfo": null,
                "productimage1": "/skydome images/c16-phone-img1.jpeg",
                "productimage2": "/skydome images/c16-phone-img2.jpeg",
                "processortype": null,
                "processorname": null,
                "brand": null,
                "batteryinfo": "4000mAh",
                "ram": "4gb",
                "rom": "64gb",
                "windowversion": "android 10",
                "color": "blue",
                "touchscreen": null,
                "netweight": null,
                "inch": "6.5",
                "gaminggraphics": null,
                "network": "4G LTE",
                "frontcamera": "13MP",
                "rearcamera": "(16+8+2+2MP Quad Rear Camera)",
                "shoesize": null,
                "productinfopreview":"Gionee S12 6.517-Inch HD (4GB RAM, 64GB ROM) Android 10..."
            },
                {
                    "no": 42,
                    "id": "c1-shoe",
                    "productname": "Vans shoe",
                    "productprice": 14000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c1-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Vans X gremlin custom old skool shoe"
                },
                {
                    "no": 43,
                    "id": "c2-shoe",
                    "productname": "Nike air jordan",
                    "productprice": 25000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c2-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Nike air jordan 1 retro high sneakers"
                },
                {
                    "no": 44,
                    "id": "c3-shoe",
                    "productname": "Nike airforce",
                    "productprice": 17000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c3-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"white nike airforce 1 shoe available in size 33-47"
                },
                {
                    "no": 45,
                    "id": "c4-shoe",
                    "productname": "Puma shoe",
                    "productprice": 11000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c4-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Puma RS-Z sneakers available in size 35-48"
                },
                {
                    "no": 46,
                    "id": "c5-shoe",
                    "productname": "Louis vuitton shoe",
                    "productprice": 34000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c5-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Louis vuitton shoe available in size 33-47"
                },
                {
                    "no": 14,
                    "id": "c9",
                    "productname": "Brown oxford shoe",
                    "productprice": 15700,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c9-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"High quality PU stitching clasic hollow"
                },
                {
                    "no": 15,
                    "id": "c10",
                    "productname": "Mens ankle boots",
                    "productprice": 17000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c10-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Men's Leather Chelsea Ankle Boots New Botas Hombre Shoe"
                },
                {
                    "no": 16,
                    "id": "c11",
                    "productname": "Striped high boots",
                    "productprice": 25000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c11-mshoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Mens black and white striped high boots"
                },
                {
                    "no": 17,
                    "id": "c12",
                    "productname": "Brown loafer shoe",
                    "productprice": 18000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c12-mshoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Brown loafer PU solid shoe"
                },
                {
                    "no": 18,
                    "id": "c13",
                    "productname": "Stilettos leopard sandals",
                    "productprice": 7500,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c13-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"High heels Stilettos elastic band leopard sandals"
                },
                {
                    "no": 19,
                    "id": "c14",
                    "productname": "High heel sandals",
                    "productprice": 7800,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c14-fshoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"High heel stiletto nylon boots "
                },
                {
                    "no": 20,
                    "id": "c15",
                    "productname": "Cross strap sandal",
                    "productprice": 7300,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c15-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"green color cross strap high heel sandal"
                },
                {
                    "no": 21,
                    "id": "c16",
                    "productname": "Hollow Mesh Sandals",
                    "productprice": 9000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c16-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Black High Heels Hollow Mesh Sandals "
                },
                {
                    "no": 48,
                    "id": "c6-shoe",
                    "productname": "Nike air jordan",
                    "productprice": 28000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c6-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Custom Nike air jordan sneakrs available in size 39-49"
                },
                {
                    "no": 49,
                    "id": "c7-shoe",
                    "productname": "Jordan pro strong",
                    "productprice": 13000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c7-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"White/Wolf Grey/Racer Blue Jordan pro strong shoe"
                },
                {
                    "no": 51,
                    "id": "c8-shoe",
                    "productname": "Adidas yeezy boost",
                    "productprice": 19000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c8-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Adidas yeezy boost 350 v2 glow in the dark shoe"
                },
                {
                    "no": 52,
                    "id": "c9-shoe",
                    "productname": "Channel shoe",
                    "productprice": 32000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c9-shoe-img (2).png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"White pin on channel shoe available in size 27-38"
                },
                {
                    "no": 53,
                    "id": "c10-shoe",
                    "productname": "White channel shoe",
                    "productprice": 57000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c10-shoe-img (2).png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"pointed toe, pearl quilted pattern and embellished CC"
                },
                {
                    "no": 54,
                    "id": "c11-shoe",
                    "productname": "Madden girl shoe",
                    "productprice": 23000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c11-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Yellow bella two-piece block heel sandals"
                },
                {
                    "no": 55,
                    "id": "c12-shoe",
                    "productname": "Micheal kors",
                    "productprice": 19000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c12-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Womens imberly ankle strap dress sandals"
                },
                {
                    "no": 56,
                    "id": "c13-shoe",
                    "productname": "Pink zara shoe",
                    "productprice": 12000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c13-shoe-img (2).png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Pink zara transparent shoe availablein size 28-39"
                },
                {
                    "no": 57,
                    "id": "c14-shoe",
                    "productname": "Bella Vita shoe",
                    "productprice": 6000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c14-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Bella Vita Womens Sarah Ii Slingback Dress Shoe Pump."
                },
                {
                    "no": 58,
                    "id": "c15-shoe",
                    "productname": "Louis V sneakers.",
                    "productprice": 19000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c15-shoe-img (2).png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Louis vouitton sneakers available in size 26-41."
                },
                {
                    "no": 59,
                    "id": "c16-shoe",
                    "productname": "Channel boots",
                    "productprice": 22000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c16-shoe-img (2).png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"black high leather channel female boots "
                },
                {
                    "no": 60,
                    "id": "c17-shoe",
                    "productname": "Red suede tie shoe",
                    "productprice": 16000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c17-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Red suede tie shoe mens shoe available in size 35-51"
                },
                {
                    "no": 61,
                    "id": "c18-shoe",
                    "productname": "Black seude shoe",
                    "productprice": 15000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c18-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"black mens seude coporate shoe available in size 36-46"
                },
                {
                    "no": 62,
                    "id": "c19-shoe",
                    "productname": "Brown leather shoe",
                    "productprice": 25000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c19-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"brown  Oxfords Tassel Casual Genuine Leather Moccasins shoe"
                },
                {
                    "no": 63,
                    "id": "c20-shoe",
                    "productname": "black seude shoe",
                    "productprice": 13000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c20-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"black mens seude coporate shoe available in size 36-46"
                },
                {
                    "no": 64,
                    "id": "c21-shoe",
                    "productname": "Welcome slides",
                    "productprice": 9000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c21-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"welcome slides available in size 30-46"
                },
                {
                    "no": 66,
                    "id": "c22-shoe",
                    "productname": "Adidas yeezy slide",
                    "productprice": 9000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c22-shoe-img.jpeg",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"gray Adidas yeezy slide bone available in size 30-48"
                },
                {
                    "no": 67,
                    "id": "c23-shoe",
                    "productname": "Balenciaga loafer",
                    "productprice": 29000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c23-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Balenciaga loafer mule half shoe"
                },
                {
                    "no": 68,
                    "id": "c24-shoe",
                    "productname": "Alexander mcQueen ",
                    "productprice": 50000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c24-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Unisex Alexander mcQueen sneakers"
                },{
                    "no": 145,
                    "id": "cm",
                    "productname": "Female criss cross shoe",
                    "productprice": 6500,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c13-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Criss Cross Open Toe Flat Sliders available in size..."
                },
                {
                    "no": 146,
                    "id": "cn",
                    "productname": "Madden girl high heels",
                    "productprice": 8000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c14-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Madden girl high heels shoe available in size 27-32"
                },
                {
                    "no": 147,
                    "id": "co",
                    "productname": "Brown toe two strap heel",
                    "productprice": 11000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c15-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Brown toe two strap heel available in size 27-33"
                },
                {
                    "no": 148,
                    "id": "cp",
                    "productname": "high sole sandals",
                    "productprice": 9000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c16-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"brown sewade high sole sandals"
                },
                {
                    "no": 141,
                    "id": "ci",
                    "productname": "Nike airforce 1",
                    "productprice": 20000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c9-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"pink nike air force 1 shoe available in size 30-48"
                },
                {
                    "no": 142,
                    "id": "cj",
                    "productname": "blue dior shoe",
                    "productprice": 35000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c10-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"blue dior shoes available in size 32-47"
                },
                {
                    "no": 143,
                    "id": "ck",
                    "productname": "Air jordan 4",
                    "productprice": 25000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c11-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Air jordan 4 retro kaws grey available in size 40-52"
                },
                {
                    "no": 144,
                    "id": "cl",
                    "productname": "Adidas yeezy boost",
                    "productprice": 18000,
                    "quantity":1,
                    "productinfo": null,
                    "productimage1": "/skydome images/c12-shoe-img.png",
                    "productimage2": null,
                    "processortype": null,
                    "processorname": null,
                    "brand": null,
                    "batteryinfo": null,
                    "ram": null,
                    "rom": null,
                    "windowversion": null,
                    "color": null,
                    "touchscreen": null,
                    "netweight": null,
                    "inch": null,
                    "gaminggraphics": null,
                    "network": null,
                    "frontcamera": null,
                    "rearcamera": null,
                    "shoe":true,
                    "shoesize": 31,
                    "productinfopreview":"Adidas yeezy boost 350 v2 cloud white shoe"
                },
                    {
                        "no": 119,
                        "id": "c19-clothe",
                        "productname": "Female skiny jean",
                        "productprice": 6000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c19-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 120,
                        "id": "c20-clothe",
                        "productname": "Denim pallazo",
                        "productprice": 7000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c20-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 121,
                        "id": "c21-clothe",
                        "productname": "Riped jean",
                        "productprice": 6750,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c21-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 122,
                        "id": "c22-clothe",
                        "productname": "Light blue jean",
                        "productprice": 6750,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c22-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 123,
                        "id": "c23-clothe",
                        "productname": "Pink joggers",
                        "productprice": 6600,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c23-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 124,
                        "id": "c24-clothe",
                        "productname": "Slit flare culottes",
                        "productprice": 12000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c24-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 125,
                        "id": "c25-clothe",
                        "productname": "Casual short sleeve",
                        "productprice": 3400,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c25-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 126,
                        "id": "c26-clothe",
                        "productname": "Casual short sleeve",
                        "productprice": 3800,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c26-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 127,
                        "id": "c27-clothe",
                        "productname": "Wine dress",
                        "productprice": 8500,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c27-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 128,
                        "id": "c28-clothe",
                        "productname": "Empari Apparel",
                        "productprice": 1100,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c28-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 129,
                        "id": "c29-clothe",
                        "productname": "Coporate ladie suit",
                        "productprice": 9900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c29-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 130,
                        "id": "c30-clothe",
                        "productname": "Orange gown",
                        "productprice": 5500,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c30-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 131,
                        "id": "31-clothe",
                        "productname": "Long gown",
                        "productprice": 3900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c31-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 132,
                        "id": "32-clothe",
                        "productname": "Coporate dress",
                        "productprice": 3800,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c32-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 133,
                        "id": "c33-clothe",
                        "productname": "Office dress",
                        "productprice": 4900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c33-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 134,
                        "id": "c34-clothe",
                        "productname": "Polka dot dress",
                        "productprice": 6590,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c34-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 135,
                        "id": "c35-clothe",
                        "productname": "Striped pants combo",
                        "productprice": 5999,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c35-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 136,
                        "id": "c36-clothe",
                        "productname": "Crop top",
                        "productprice": 3500,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c36-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 137,
                        "id": "c37-clothe",
                        "productname": "Bell sleeves crop top",
                        "productprice": 3800,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c37-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 138,
                        "id": "c38-clothe",
                        "productname": "White Body suit",
                        "productprice": 3000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c38-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 101,
                        "id": "c1-clothe",
                        "productname": "2 piece cloth",
                        "productprice": 9000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c1-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 102,
                        "id": "c2-clothe",
                        "productname": "Vintage shirt",
                        "productprice": 4900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c2-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 103,
                        "id": "c3-clothe",
                        "productname": "Striped lapel",
                        "productprice": 5300,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c3-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 104,
                        "id": "c4-clothe",
                        "productname": "Hilfigher hoodie",
                        "productprice": 8000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c4-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 105,
                        "id": "c5-clothe",
                        "productname": "Black trouser",
                        "productprice": 5900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c5-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 106,
                        "id": "c6-clothe",
                        "productname": "Blue lean",
                        "productprice": 28000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c6-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 107,
                        "id": "c7-clothe",
                        "productname": "Joggers",
                        "productprice": 5000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c7-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 108,
                        "id": "c8-clothe",
                        "productname": "Cargo joggers",
                        "productprice": 8900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c8-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 109,
                        "id": "c9-clothe",
                        "productname": "El-vin GREY pack",
                        "productprice": 32000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c9-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 110,
                        "id": "c10-clothe",
                        "productname": " Offwhite sweater",
                        "productprice": 6500,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c10-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 111,
                        "id": "c11-clothe",
                        "productname": "Baggy joggers",
                        "productprice": 4900,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c11-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 112,
                        "id": "c12-clothe",
                        "productname": "T-shirt",
                        "productprice": 4590,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c12-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 113,
                        "id": "c13-clothe",
                        "productname": "Coperate trouser",
                        "productprice": 5000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c13-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 114,
                        "id": "c14-clothe",
                        "productname": "Striped suit trouser",
                        "productprice": 6000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c14-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 115,
                        "id": "c15-clothe",
                        "productname": "Wine suit",
                        "productprice": 20000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c15-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 116,
                        "id": "c16-clothe",
                        "productname": "Tuxedo suit",
                        "productprice": 39000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c16-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 117,
                        "id": "c17-clothe",
                        "productname": "Diamond floral suit",
                        "productprice": 27000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c17-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 118,
                        "id": "c18-clothe",
                        "productname": "Brown chinok",
                        "productprice": 5000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/c18-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 139,
                        "id": "c39-clothe",
                        "productname": "Baggy trouser",
                        "productprice": 6000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/39-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                    {
                        "no": 140,
                        "id": "40-clothe",
                        "productname": "White designer pants",
                        "productprice": 3000,
                        "quantity":1,
                        "productinfo": null,
                        "productimage1": "/skydome images/40-clothe.jpeg",
                        "productimage2": null,
                        "processortype": null,
                        "processorname": null,
                        "brand": null,
                        "batteryinfo": null,
                        "ram": null,
                        "rom": null,
                        "windowversion": null,
                        "color": null,
                        "touchscreen": null,
                        "netweight": null,
                        "inch": null,
                        "gaminggraphics": null,
                        "network": null,
                        "frontcamera": null,
                        "rearcamera": null,
                        "shoe":true,
                        "shoesize": 31,
                    },
                        {
                            "no": 22,
                            "id": "c17",
                            "productname": "Apple ear buds",
                            "productprice": 20000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c17-earbuds-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Apple beats by dre ear buds"
                        },
                        {
                            "no": 24,
                            "id": "c18",
                            "productname": "Air pod pro",
                            "productprice": 11000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c18-earbuds-img.jpg",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"wireless bluetooth air pods with noise..."
                        },
                        {
                            "no": 29,
                            "id": "c19",
                            "productname": "Samsung buds pro",
                            "productprice": 72000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c19-earbuds-img.jpeg",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Samsung galaxy buds pro phantom silver"
                        },
                        {
                            "no": 30,
                            "id": "c20",
                            "productname": " earphones",
                            "productprice": 3900,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c20-earbuds-img.jpeg",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Bluetooth 5.0 earphones LED display touch control headset"
                        },
                        {
                            "no": 35,
                            "id": "c21",
                            "productname": "gas cooker",
                            "productprice": 59000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c21-gascooker-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"gas cooker with space for six gas cookers,one..."
                        },
                        {
                            "no": 97,
                            "id": "c22",
                            "productname": "Center Carpet",
                            "productprice": 10000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c22-carpet-img.jpeg",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Flannel Center Rug/Carpet - 120*160cm"
                        },
                        {
                            "no": 96,
                            "id": "c23",
                            "productname": "Mobile Wardrobe",
                            "productprice": 27900,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c23-wardrope-img.jpeg",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Steel Mobile Wardrobe With 3 Real Standard Doors"
                        },
                        {
                            "no": 41,
                            "id": "c24",
                            "productname": "Vitafoam mattress",
                            "productprice": 112000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c24-mattress-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Vita foam semi orthopedic mattress"
                        },
                        {
                            "no": 31,
                            "id": "c25",
                            "productname": "Gucci bag",
                            "productprice": 651000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c25-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"GG Marmont Small Top Handle Bag"
                        },
                        {
                            "no": 32,
                            "id": "c26",
                            "productname": "Louis Vuitton Bag",
                            "productprice": 483000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c26-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Louis Vuitton Bandoulier Speedy Bag"
                        },
                        {
                            "no": 36,
                            "id": "c27",
                            "productname": "Hermes hand bag",
                            "productprice": 9840000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c27-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Caviar Quilted Small Double Flap Grey..."
                        },
                        {
                            "no": 34,
                            "id": "c28",
                            "productname": "Black dior bag",
                            "productprice": 1230000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c28-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Dior Lady Supple Limited Detachable Strap Black ..."
                        },
                        {
                            "no": 37,
                            "id": "c29",
                            "productname": "louis vuitton set",
                            "productprice": 840000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c29-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"four piece louis vuitton set shoe,bag,purse"
                        },
                        {
                            "no": 38,
                            "id": "c30",
                            "productname": "Louis vuitton bag",
                            "productprice": 440000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c30-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"Three piece louis vuitton haumea hand bag"
                        },
                        {
                            "no": 39,
                            "id": "c31",
                            "productname": "Channel set",
                            "productprice": 790000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c31-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"four pink channel hand bags"
                        },
                        {
                            "no": 40,
                            "id": "c32",
                            "productname": "Coco chanel set",
                            "productprice": 860000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c32-bag-img.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"coco chanel five peice black hand bag"
                        },
                        {
                            "no": 25,
                            "id": "c33",
                            "productname": "Black dior shirt",
                            "productprice": 19000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c33-shirt-img.png.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"black dior shirt"
                        },
                        {
                            "no": 26,
                            "id": "c34",
                            "productname": "Black designer shirt",
                            "productprice": 11000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c34-shirt-img.png.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"black designer shirt"
                        },
                        {
                            "no": 27,
                            "id": "c35",
                            "productname": "Black dior shirt",
                            "productprice": 16000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c35-shirt-img.png.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"black dior shirt"
                        },
                        {
                            "no": 28,
                            "id": "c36",
                            "productname": "Blue gucci sweater",
                            "productprice": 32000,
                            "quantity":1,
                            "productinfo": null,
                            "productimage1": "/skydome images/c36-shirt-img.png.png",
                            "productimage2": null,
                            "processortype": null,
                            "processorname": null,
                            "brand": null,
                            "batteryinfo": null,
                            "ram": null,
                            "rom": null,
                            "windowversion": null,
                            "color": null,
                            "touchscreen": null,
                            "netweight": null,
                            "inch": null,
                            "gaminggraphics": null,
                            "network": null,
                            "frontcamera": null,
                            "rearcamera": null,
                            "shoesize": null,
                            "productinfopreview":"blue gucci sweater"
                        }
]