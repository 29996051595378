export const searchBarData=[
    {
      id:1,
      product:'hp 250 g8'
    },
    {
      id:2,
      product:'lenovo legion 5'
    },
    {
      id:3,
      product:'Nike airforce 1'
    },
    {
      id:4,
      product:'blue dior shoe'
    },
    {
      id:5,
      product:'Air jordan 4'
    },
    {
      id:6,
      product:'Adidas yeezy boost'
    },
    {
      id:7,
      product:'Female criss cross shoe'
    },
    {
      id:8,
      product:'mac book pro'
    },
    {
      id:9,
      product:'Madden girl high heels'
    },
    {
      id:10,
      product:'Brown toe two strap heel'
    },
    {
      id:11,
      product:'high sole sandals'
    },
    {
      id:12,
      product:'Apple ear buds'
    },
    {
      id:13,
      product:'Air pod pro'
    },
    {
      id:14,
      product:'Samsung buds pro'
    },
    {
      id:15,
      product:'earphones'
    },
    {
      id:16,
      product:'Center Carpet'
    },
    {
      id:17,
      product:'Mobile Wardrobe'
    },
    {
      id:18,
      product:'Vitafoam mattress'
    },
    {
      id:19,
      product:'Gucci bag'
    },
    {
      id:20,
      product:'Louis Vuitton Bag'
    },
    {
      id:21,
      product:'Hermes hand bag'
    },
    {
      id:22,
      product:'Black dior bag'
    },
    {
      id:23,
      product:'louis vuitton set'
    },
    {
      id:24,
      product:'louis vuitton bag'
    },
    {
      id:25,
      product:'Channel set'
    },
    {
      id:26,
      product:'Coco chanel set'
    },
    {
      id:27,
      product:'Black dior shirt'
    },
    {
      id:28,
      product:'Black designer shirt'
    },
    {
      id:29,
      product:'Blue gucci sweater'
    },
    {
      id:30,
      product:'XIAOMI poco m4 pro'
    },
    {
      id:31,
      product:'Tecno POVA Neo'
    },
    {
      id:32,
      product:'Samsung Galaxy A23'
    },
    {
      id:33,
      product:'Infinix note 11 pro'
    },
    {
      id:34,
      product:'acer predator helios'
    },
  ]


