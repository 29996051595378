export const  searchBarData=[
    {
      id:1,
      product:' DELL XPS 17'
    },
    {
      id:2,
      product:'DELL G5 Gamming pc'
    },
    {
      id:3,
      product:'DELL latitude 3310'
    },
    {
      id:4,
      product:'Acer nitro 5'
    },
    {
      id:5,
      product:'Hp omen 16'
    },
    {
      id:6,
      product:'Asus ROG ZEPHYRUS G1'
    },
    {
      id:7,
      product:'Apple macbook air'
    },
    {
      id:8,
      product:'Razer blade Stealth'
    },
    {
      id:9,
      product:'Lenovo v15'
    },
    {
      id:10,
      product:'Microsoft surface pro 6'
    },
    {
      id:11,
      product:'Hp 240 G8'
    },
    {
      id:12,
      product:'Asus gaming pc'
    },
    {
      id:13,
      product:'DELL ALIENWARE'
    },
    {
      id:14,
      product:'infinix INbook'
    },
    {
      id:15,
      product:'Hp Pavilion 15'
    },
  ]

export const laptopProd=[
    {
        "no": 69,
        "id": "c1-laptop",
        "productname": " DELL XPS 17",
        "productprice": 1555000,
        "quantity":1,
        "productinfo": "DELL XPS 17 9700 10th generation laptop",
        "productimage1": "/skydome images/c1-laptop-img.jpeg",
        "productimage2": "/skydome images/c1-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core19",
        "brand": "DELL",
        "batteryinfo": "Primary Battery 6-Cell Battery, 97WHr (Integrated)",
        "ram": "32",
        "rom": "1tb ssd",
        "windowversion": "windows 10 pro",
        "color": "silver",
        "touchscreen": "no",
        "netweight": "2.51kg",
        "inch": "17.0",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6 with Max-Q",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Dell xps 32gb ram 1tb ssd,windows 10, core 19..."
    },
    {
        "no": 71,
        "id": "c2-laptop",
        "productname": "DELL G5 Gamming pc",
        "productprice": 460000,
        "quantity":1,
        "productinfo": "DELL G5 Gamming 10th generation laptop",
        "productimage1": "/skydome images/c2-laptop-img1.jpeg",
        "productimage2": "/skydome images/c2-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core15",
        "brand": "DELL",
        "batteryinfo": "Li-polymer 15.2v 68Wh 4-cell battery",
        "ram": "8",
        "rom": "256 ssd",
        "windowversion": "windows 10 pro",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2.8kg",
        "inch": "15.6",
        "gaminggraphics": "4gb ram NVIDIA GeForce GTX 1650Ti",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Dell G5 gamming laptop 8gb ram,256gb ssd,windows 10h"
    },
    {
        "no": 72,
        "id": "c3-laptop",
        "productname": "Asus X515JA",
        "productprice": 214000,
        "quantity":1,
        "productinfo": "Asus X515JA laptop",
        "productimage1": "/skydome images/c3-laptop-img1.jpeg",
        "productimage2": "/skydome images/c3-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core13",
        "brand": "ASUS",
        "batteryinfo": "Lithium Battery Energy Content ?37 Watt Hours 2 ion cells",
        "ram": "4",
        "rom": "1tb ssd",
        "windowversion": "windows 10",
        "color": "Transparent silver",
        "touchscreen": "no",
        "netweight": "1.7kg",
        "inch": "15.6",
        "gaminggraphics": "intel graphics card",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus x515ja ,4gb ram,1tb hdd,windows 10,core i3"
    },
    {
        "no": 153,
        "id": "c1",
        "productname": "DELL latitude 3310",
        "productprice": 265000,
        "quantity":1,
        "productinfo": "DELL latitude 3310 core i3 256GB SSD..",
        "productimage1": "/skydome images/dell-latitude-img1.jpeg",
        "productimage2": "/skydome images/dell-latitude-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "dell",
        "batteryinfo": "Polymer 3c 42 Watts battery with 11.40 VDC voltage",
        "ram": "8gb",
        "rom": "256GB",
        "windowversion": "windows 10 pro(64-bit)",
        "color": "black",
        "touchscreen": "no",
        "netweight": "1.17kg",
        "inch": "13.3",
        "gaminggraphics": "intel UHD graphics 620",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"DELL latitude 3310 core i3 256GB SSD.."
	
    },
    {
        "no": 154,
        "id": "c2",
        "productname": "Acer nitro 5",
        "productprice": 770000,
        "quantity":1,
        "productinfo": "9th generation intel core i7 -9750H gamming laptop with backlit keybord",
        "productimage1": "/skydome images/acer-nitro-5-img1.jpeg",
        "productimage2": "/skydome images/acer-nitro-5-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "acer",
        "batteryinfo": "4 cell 57.5 Wh Lithium-Ion battery",
        "ram": "16GB DDR4 2666MHz",
        "rom": "256gb ssd",
        "windowversion": "windows 10 home",
        "color": "black",
        "touchscreen": "no",
        "netweight": "3kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"acer nitro 5 core i7 16gb ram 512gb 4gb nivida win..."
    },
    {
        "no": 155,
        "id": "c3",
        "productname": "Hp omen 16",
        "productprice": 1250000,
        "quantity":1,
        "productinfo": "Hp omen 16 (60H77EA)",
        "productimage1": "/skydome images/hp-omen-img1.jpeg",
        "productimage2": "/skydome images/hp-omen-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "hp",
        "batteryinfo": "6-cell 83Wh Li-ion polymer",
        "ram": "32gb",
        "rom": "1tb ssd",
        "windowversion": "Windows 11 Home",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2kg",
        "inch": "16.1",
        "gaminggraphics": "NVIDIA GeForce RTX 3070 8GB GDDR6",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Hp omen 16 gaming 11th Gen Core i7..."
    },
    {
        "no": 156,
        "id": "c4",
        "productname": "Asus ROG ZEPHYRUS G1",
        "productprice": 2000000,
        "quantity":1,
        "productinfo": "Asus ROG ZEPHYRUS G1 AMD RYZEN 9-5900H",
        "productimage1": "/skydome images/Asus ROG-img1.jpeg",
        "productimage2": "/skydome images/Asus ROG-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Asus",
        "batteryinfo": "1 Lithium ion batterie with 20Volts",
        "ram": "40gb DDR4",
        "rom": "2TB ssd",
        "windowversion": "windows 10",
        "color": "Off black",
        "touchscreen": "no",
        "netweight": "2.60kg",
        "inch": "15.51",
        "gaminggraphics": "8GB NVIDIA GeForce RTX 3070",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus ROG ZEPHYRUS G1, AMD RYZEN 9-5900H,2TB SSD/40GB..."
    },
    {
        "no": 73,
        "id": "c4-laptop",
        "productname": "Apple macbook air",
        "productprice": 590000,
        "quantity":1,
        "productinfo": "Apple macbook air laptop with M1 chip",
        "productimage1": "/skydome images/c4-laptop-img1.jpeg",
        "productimage2": "/skydome images/c4-laptop-img2.jpeg",
        "processortype": "Apple M1",
        "processorname": "corei5",
        "brand": "Apple",
        "batteryinfo": "30W USB Type-C Power Adapter",
        "ram": "8gb",
        "rom": "256 ssd",
        "windowversion": "macOS Big Sur 11.0",
        "color": "space grey",
        "touchscreen": "no",
        "netweight": "0.7kg",
        "inch": "13",
        "gaminggraphics": "2gb ram",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Apple macbook air 13' M1 chip core 15 8gb ram 256gb rom"
    },
    {
        "no": 74,
        "id": "c5-laptop",
        "productname": "Razer blade Stealth",
        "productprice": 682000,
        "quantity":1,
        "productinfo": "Razer Blade Stealth 13 GAMING laptop",
        "productimage1": "/skydome images/c5-laptop-img1.jpeg",
        "productimage2": "/skydome images/c5-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Razer",
        "batteryinfo": "53.1Wh battery with up to 10hours battery life",
        "ram": "16gb",
        "rom": "256gb ssd",
        "windowversion": "windows 10",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2.9kg",
        "inch": "13.3",
        "gaminggraphics": "NVIDIA GeForce GTX MX150 graphics card.",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Razer Blade Stealth 13 GAMING laptop,core i7,16gb ram 256.."
    },
    {
        "no": 75,
        "id": "c6-laptop",
        "productname": "Lenovo v15",
        "productprice": 258000,
        "quantity":1,
        "productinfo": "Lenovo v15 laptop",
        "productimage1": "/skydome images/c6-laptop-img1.jpeg",
        "productimage2": "/skydome images/c6-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "Lenovo",
        "batteryinfo": "4 Lithium Ion Cells with up to 8hours",
        "ram": "26gb",
        "rom": "1tb hdd",
        "windowversion": "windows 10",
        "color": "black",
        "touchscreen": "no",
        "netweight": "1.1kg",
        "inch": "15.6",
        "gaminggraphics": "Intel HD Graphics 610",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Lenovo V15 core i3 ,12gb ram,1tb hdd,windows 10..."
    },
    {
        "no": 76,
        "id": "c7-laptop",
        "productname": "Microsoft surface pro 6",
        "productprice": 645000,
        "quantity":1,
        "productinfo": "Microsoft surface pro 6 laptop",
        "productimage1": "/skydome images/c7-laptop-img1.jpeg",
        "productimage2": "/skydome images/c7-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Microsoft",
        "batteryinfo": "up to 6hours",
        "ram": "16gb",
        "rom": "512 ssd",
        "windowversion": "windows 10",
        "color": "platinum",
        "touchscreen": "no",
        "netweight": "0.77kg",
        "inch": "12.3",
        "gaminggraphics": "Intel HD Graphics ",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Microsoft Surface Pro 6 12.3' Intel Corei7 512GB/16GB..."
    },
    {
        "no": 77,
        "id": "c8-laptop",
        "productname": "Hp 240 G8 ",
        "productprice": 280000,
        "quantity":1,
        "productinfo": "Hp 240 G8 laptop",
        "productimage1": "/skydome images/c8-laptop-img1.jpeg",
        "productimage2": "/skydome images/c8-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "HP",
        "batteryinfo": "PowerAC adapter power45 W",
        "ram": "12gb",
        "rom": "1tb hdd",
        "windowversion": "windows 10 pro",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "1.47kg",
        "inch": "12.8",
        "gaminggraphics": "Intel HD Graphics ",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Hp 240 G8 Intel Core I3 10th Gen 1TB HDD 12GB RAM WIN 10.."
    },
    {
        "no": 78,
        "id": "c9-laptop",
        "productname": "Asus gaming pc",
        "productprice": 690000,
        "quantity":1,
        "productinfo": "Asus ROG STRIX gaming laptop",
        "productimage1": "/skydome images/c9-laptop-img1.jpeg",
        "productimage2": "/skydome images/c9-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Asus",
        "batteryinfo": "1 Lithium ion battery",
        "ram": "16gb",
        "rom": "1tb ssd",
        "windowversion": "windows 10 home",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "1.47kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce GTX 1050 4GB graphics. Dual-fan thermal design",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus ROG STRIX Gaming Laptop 15.6” Core I7-7700HQ 16GB..."
    },
    {
        "no": 79,
        "id": "c10-laptop",
        "productname": "DELL ALIENWARE",
        "productprice": 1100000,
        "quantity":1,
        "productinfo": "DELL ALIENWARE M15 R3 9th generation laptop",
        "productimage1": "/skydome images/c10-laptop-img.png",
        "productimage2": "/skydome images/c10-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "DELL",
        "batteryinfo": "1 Lithium ion battery",
        "ram": "16gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10 home",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "4.7kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"DELL ALIENWARE M15 R3 - INTEL CORE I7-10750H, 15.6..."
    },
    {
        "no": 80,
        "id": "c11-laptop",
        "productname": "infinix INbook",
        "productprice": 470000,
        "quantity":1,
        "productinfo": "infinix INbook laptop",
        "productimage1": "/skydome images/c11-laptop-img2.jpeg",
        "productimage2": "/skydome images/c11-laptop-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "INFINIX",
        "batteryinfo": " 55Wh battery Up to 13 Hours of Web Browsing",
        "ram": "16gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10 home",
        "color": "Grey",
        "touchscreen": "no",
        "netweight": "1.4kg",
        "inch": "14",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Infinix INBook Intel Core I7, 16GB RAM/512GB Ssd X1 Pro..."
    },
    {
        "no": 81,
        "id": "c12-laptop",
        "productname": "Hp Pavilion 15",
        "productprice": 495000,
        "quantity":1,
        "productinfo": "Pavilion 15 CONVERTIBLE laptop",
        "productimage1": "/skydome images/c12-laptop-img2.jpeg",
        "productimage2": "/skydome images/c12-laptop-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "HP",
        "batteryinfo": "41.5Wh 3 cell Li-polymer battery",
        "ram": "8gb",
        "rom": "256gb ssd",
        "windowversion": "windows 10 home",
        "color": "Black and silver",
        "touchscreen": "Yes",
        "netweight": "1.32kg",
        "inch": "15.6",
        "gaminggraphics": "intel graphics",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Pavilion 15 CONVERTIBLE,touch screen,corei7,8 generation.."
    }
]


export const allProd=[
    {
        "no": 1,
        "id": "ca",
        "productname": "hp 250 g8",
        "productprice": 325000,
        "productinfo": "hp 250 g8 10th generation laptop",
        "productimage1": "/skydome images/hp-250-g8-img1.jpeg",
        "productimage2": "/skydome images/hp-250-g8-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "quantity":1,
        "brand": "hp",
        "batteryinfo": "45watt li-on battery",
        "ram": "8gb",
        "rom": "1tbhdd",
        "windowversion": "windows 10 pro",
        "color": "silver",
        "touchscreen": "no",
        "netweight": "1.3kg",
        "inch": "15",
        "gaminggraphics": "2gb dedicated gaming graphics",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"hp 250 g8 laptop 8gb ram 1tb hdd windows 10pro core i5"
	
    },
    {
        "no": 3,
        "id": "cb",
        "productname": "lenovo legion 5",
        "productprice": 650000,
        "productinfo": "lenovo legion 5 15IMH05 10th generation laptop",
        "productimage1": "/skydome images/lenovo-legion-5-img1.png",
        "productimage2": "/skydome images/lenovo-legion-5-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "quantity":1,
        "brand": "lenovo",
        "batteryinfo": "integrated 60Wh",
        "ram": "8gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10 home",
        "color": "black",
        "touchscreen": "no",
        "netweight": "1.3kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GTX 4GB GDDR6",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"lenovo legion 5 core i5 8gb ram 512gb 4gb nivida win..."
    },
    {
        "no": 4,
        "id": "cc",
        "productname": "mac book pro",
        "productprice": 1395000,
        "productinfo": "apple mac book pro 16\" AMD Raedon Pro 5500M",
        "productimage1": "/skydome images/mac-book-pro-img2.jpeg",
        "productimage2": "/skydome images/mac-book-pro-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei9",
        "quantity":1,
        "brand": "Apple",
        "batteryinfo": "96W USB-C power adapter Up to 11 hours battery life",
        "ram": "16gb",
        "rom": "1tb ssd",
        "windowversion": "mac os",
        "color": "space grey",
        "touchscreen": "no",
        "netweight": "2kg",
        "inch": "14.09",
        "gaminggraphics": "AMD Radeon Pro 5500M graphics card With 4GB of GDDR6 VRAM",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"apple mac book pro touch bar core i9 16gb 1tb ssd AMD..."
    },
    {
        "no": 6,
        "id": "cd",
        "productname": "acer predator helios",
        "productprice": 810000,
        "productinfo": "acer predator helios 300",
        "productimage1": "/skydome images/acer-predator-helios-img2.jpeg",
        "productimage2": "/skydome images/acer-predator-helios-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "quantity":1,
        "brand": "Acer",
        "batteryinfo": "Average Battery Life (in hours) ?6 Hours",
        "ram": "16gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2.5kg",
        "inch": "15.6",
        "gaminggraphics": "Dedicated NVIDIA GeForce RTX 3060 6GB",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"acer predator helios 300 corei7 16gb ram,..."
    },
    {
        "no": 69,
        "id": "c1-laptop",
        "productname": " DELL XPS 17",
        "productprice": 1555000,
        "quantity":1,
        "productinfo": "DELL XPS 17 9700 10th generation laptop",
        "productimage1": "/skydome images/c1-laptop-img.jpeg",
        "productimage2": "/skydome images/c1-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core19",
        "brand": "DELL",
        "batteryinfo": "Primary Battery 6-Cell Battery, 97WHr (Integrated)",
        "ram": "32",
        "rom": "1tb ssd",
        "windowversion": "windows 10 pro",
        "color": "silver",
        "touchscreen": "no",
        "netweight": "2.51kg",
        "inch": "17.0",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6 with Max-Q",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Dell xps 32gb ram 1tb ssd,windows 10, core 19..."
    },
    {
        "no": 71,
        "id": "c2-laptop",
        "productname": "DELL G5 Gamming pc",
        "productprice": 460000,
        "quantity":1,
        "productinfo": "DELL G5 Gamming 10th generation laptop",
        "productimage1": "/skydome images/c2-laptop-img1.jpeg",
        "productimage2": "/skydome images/c2-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core15",
        "brand": "DELL",
        "batteryinfo": "Li-polymer 15.2v 68Wh 4-cell battery",
        "ram": "8",
        "rom": "256 ssd",
        "windowversion": "windows 10 pro",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2.8kg",
        "inch": "15.6",
        "gaminggraphics": "4gb ram NVIDIA GeForce GTX 1650Ti",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Dell G5 gamming laptop 8gb ram,256gb ssd,windows 10h"
    },
    {
        "no": 72,
        "id": "c3-laptop",
        "productname": "Asus X515JA",
        "productprice": 214000,
        "quantity":1,
        "productinfo": "Asus X515JA laptop",
        "productimage1": "/skydome images/c3-laptop-img1.jpeg",
        "productimage2": "/skydome images/c3-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "core13",
        "brand": "ASUS",
        "batteryinfo": "Lithium Battery Energy Content ?37 Watt Hours 2 ion cells",
        "ram": "4",
        "rom": "1tb ssd",
        "windowversion": "windows 10",
        "color": "Transparent silver",
        "touchscreen": "no",
        "netweight": "1.7kg",
        "inch": "15.6",
        "gaminggraphics": "intel graphics card",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus x515ja ,4gb ram,1tb hdd,windows 10,core i3"
    },
    {
        "no": 153,
        "id": "c1",
        "productname": "DELL latitude 3310",
        "productprice": 265000,
        "quantity":1,
        "productinfo": "DELL latitude 3310 core i3 256GB SSD..",
        "productimage1": "/skydome images/dell-latitude-img1.jpeg",
        "productimage2": "/skydome images/dell-latitude-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "dell",
        "batteryinfo": "Polymer 3c 42 Watts battery with 11.40 VDC voltage",
        "ram": "8gb",
        "rom": "256GB",
        "windowversion": "windows 10 pro(64-bit)",
        "color": "black",
        "touchscreen": "no",
        "netweight": "1.17kg",
        "inch": "13.3",
        "gaminggraphics": "intel UHD graphics 620",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"DELL latitude 3310 core i3 256GB SSD.."
	
    },
    {
        "no": 154,
        "id": "c2",
        "productname": "Acer nitro 5",
        "productprice": 770000,
        "quantity":1,
        "productinfo": "9th generation intel core i7 -9750H gamming laptop with backlit keybord",
        "productimage1": "/skydome images/acer-nitro-5-img1.jpeg",
        "productimage2": "/skydome images/acer-nitro-5-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "acer",
        "batteryinfo": "4 cell 57.5 Wh Lithium-Ion battery",
        "ram": "16GB DDR4 2666MHz",
        "rom": "256gb ssd",
        "windowversion": "windows 10 home",
        "color": "black",
        "touchscreen": "no",
        "netweight": "3kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB GDDR6",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"acer nitro 5 core i7 16gb ram 512gb 4gb nivida win..."
    },
    {
        "no": 155,
        "id": "c3",
        "productname": "Hp omen 16",
        "productprice": 1250000,
        "quantity":1,
        "productinfo": "Hp omen 16 (60H77EA)",
        "productimage1": "/skydome images/hp-omen-img1.jpeg",
        "productimage2": "/skydome images/hp-omen-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "hp",
        "batteryinfo": "6-cell 83Wh Li-ion polymer",
        "ram": "32gb",
        "rom": "1tb ssd",
        "windowversion": "Windows 11 Home",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2kg",
        "inch": "16.1",
        "gaminggraphics": "NVIDIA GeForce RTX 3070 8GB GDDR6",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Hp omen 16 gaming 11th Gen Core i7..."
    },
    {
        "no": 156,
        "id": "c4",
        "productname": "Asus ROG ZEPHYRUS G1",
        "productprice": 2000000,
        "quantity":1,
        "productinfo": "Asus ROG ZEPHYRUS G1 AMD RYZEN 9-5900H",
        "productimage1": "/skydome images/Asus ROG-img1.jpeg",
        "productimage2": "/skydome images/Asus ROG-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Asus",
        "batteryinfo": "1 Lithium ion batterie with 20Volts",
        "ram": "40gb DDR4",
        "rom": "2TB ssd",
        "windowversion": "windows 10",
        "color": "Off black",
        "touchscreen": "no",
        "netweight": "2.60kg",
        "inch": "15.51",
        "gaminggraphics": "8GB NVIDIA GeForce RTX 3070",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus ROG ZEPHYRUS G1, AMD RYZEN 9-5900H,2TB SSD/40GB..."
    },
    {
        "no": 73,
        "id": "c4-laptop",
        "productname": "Apple macbook air",
        "productprice": 590000,
        "quantity":1,
        "productinfo": "Apple macbook air laptop with M1 chip",
        "productimage1": "/skydome images/c4-laptop-img1.jpeg",
        "productimage2": "/skydome images/c4-laptop-img2.jpeg",
        "processortype": "Apple M1",
        "processorname": "corei5",
        "brand": "Apple",
        "batteryinfo": "30W USB Type-C Power Adapter",
        "ram": "8gb",
        "rom": "256 ssd",
        "windowversion": "macOS Big Sur 11.0",
        "color": "space grey",
        "touchscreen": "no",
        "netweight": "0.7kg",
        "inch": "13",
        "gaminggraphics": "2gb ram",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Apple macbook air 13' M1 chip core 15 8gb ram 256gb rom"
    },
    {
        "no": 74,
        "id": "c5-laptop",
        "productname": "Razer blade Stealth",
        "productprice": 682000,
        "quantity":1,
        "productinfo": "Razer Blade Stealth 13 GAMING laptop",
        "productimage1": "/skydome images/c5-laptop-img1.jpeg",
        "productimage2": "/skydome images/c5-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Razer",
        "batteryinfo": "53.1Wh battery with up to 10hours battery life",
        "ram": "16gb",
        "rom": "256gb ssd",
        "windowversion": "windows 10",
        "color": "black",
        "touchscreen": "no",
        "netweight": "2.9kg",
        "inch": "13.3",
        "gaminggraphics": "NVIDIA GeForce GTX MX150 graphics card.",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Razer Blade Stealth 13 GAMING laptop,core i7,16gb ram 256.."
    },
    {
        "no": 75,
        "id": "c6-laptop",
        "productname": "Lenovo v15",
        "productprice": 258000,
        "quantity":1,
        "productinfo": "Lenovo v15 laptop",
        "productimage1": "/skydome images/c6-laptop-img1.jpeg",
        "productimage2": "/skydome images/c6-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "Lenovo",
        "batteryinfo": "4 Lithium Ion Cells with up to 8hours",
        "ram": "26gb",
        "rom": "1tb hdd",
        "windowversion": "windows 10",
        "color": "black",
        "touchscreen": "no",
        "netweight": "1.1kg",
        "inch": "15.6",
        "gaminggraphics": "Intel HD Graphics 610",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Lenovo V15 core i3 ,12gb ram,1tb hdd,windows 10..."
    },
    {
        "no": 76,
        "id": "c7-laptop",
        "productname": "Microsoft surface pro 6",
        "productprice": 645000,
        "quantity":1,
        "productinfo": "Microsoft surface pro 6 laptop",
        "productimage1": "/skydome images/c7-laptop-img1.jpeg",
        "productimage2": "/skydome images/c7-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Microsoft",
        "batteryinfo": "up to 6hours",
        "ram": "16gb",
        "rom": "512 ssd",
        "windowversion": "windows 10",
        "color": "platinum",
        "touchscreen": "no",
        "netweight": "0.77kg",
        "inch": "12.3",
        "gaminggraphics": "Intel HD Graphics ",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Microsoft Surface Pro 6 12.3' Intel Corei7 512GB/16GB..."
    },
    {
        "no": 77,
        "id": "c8-laptop",
        "productname": "Hp 240 G8 ",
        "productprice": 280000,
        "quantity":1,
        "productinfo": "Hp 240 G8 laptop",
        "productimage1": "/skydome images/c8-laptop-img1.jpeg",
        "productimage2": "/skydome images/c8-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei3",
        "brand": "HP",
        "batteryinfo": "PowerAC adapter power45 W",
        "ram": "12gb",
        "rom": "1tb hdd",
        "windowversion": "windows 10 pro",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "1.47kg",
        "inch": "12.8",
        "gaminggraphics": "Intel HD Graphics ",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Hp 240 G8 Intel Core I3 10th Gen 1TB HDD 12GB RAM WIN 10.."
    },
    {
        "no": 78,
        "id": "c9-laptop",
        "productname": "Asus gaming pc",
        "productprice": 690000,
        "quantity":1,
        "productinfo": "Asus ROG STRIX gaming laptop",
        "productimage1": "/skydome images/c9-laptop-img1.jpeg",
        "productimage2": "/skydome images/c9-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "Asus",
        "batteryinfo": "1 Lithium ion battery",
        "ram": "16gb",
        "rom": "1tb ssd",
        "windowversion": "windows 10 home",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "1.47kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce GTX 1050 4GB graphics. Dual-fan thermal design",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Asus ROG STRIX Gaming Laptop 15.6” Core I7-7700HQ 16GB..."
    },
    {
        "no": 79,
        "id": "c10-laptop",
        "productname": "DELL ALIENWARE",
        "productprice": 1100000,
        "quantity":1,
        "productinfo": "DELL ALIENWARE M15 R3 9th generation laptop",
        "productimage1": "/skydome images/c10-laptop-img.png",
        "productimage2": "/skydome images/c10-laptop-img2.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "DELL",
        "batteryinfo": "1 Lithium ion battery",
        "ram": "16gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10 home",
        "color": "Black",
        "touchscreen": "no",
        "netweight": "4.7kg",
        "inch": "15.6",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"DELL ALIENWARE M15 R3 - INTEL CORE I7-10750H, 15.6..."
    },
    {
        "no": 80,
        "id": "c11-laptop",
        "productname": "infinix INbook",
        "productprice": 470000,
        "quantity":1,
        "productinfo": "infinix INbook laptop",
        "productimage1": "/skydome images/c11-laptop-img2.jpeg",
        "productimage2": "/skydome images/c11-laptop-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "INFINIX",
        "batteryinfo": " 55Wh battery Up to 13 Hours of Web Browsing",
        "ram": "16gb",
        "rom": "512gb ssd",
        "windowversion": "windows 10 home",
        "color": "Grey",
        "touchscreen": "no",
        "netweight": "1.4kg",
        "inch": "14",
        "gaminggraphics": "NVIDIA GeForce RTX 2060 6GB graphics and 6-phase voltage regulation",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Infinix INBook Intel Core I7, 16GB RAM/512GB Ssd X1 Pro..."
    },
    {
        "no": 81,
        "id": "c12-laptop",
        "productname": "Hp Pavilion 15",
        "productprice": 495000,
        "quantity":1,
        "productinfo": "Pavilion 15 CONVERTIBLE laptop",
        "productimage1": "/skydome images/c12-laptop-img2.jpeg",
        "productimage2": "/skydome images/c12-laptop-img1.jpeg",
        "processortype": "intel",
        "processorname": "corei7",
        "brand": "HP",
        "batteryinfo": "41.5Wh 3 cell Li-polymer battery",
        "ram": "8gb",
        "rom": "256gb ssd",
        "windowversion": "windows 10 home",
        "color": "Black and silver",
        "touchscreen": "Yes",
        "netweight": "1.32kg",
        "inch": "15.6",
        "gaminggraphics": "intel graphics",
        "network": null,
        "frontcamera": null,
        "rearcamera": null,
        "shoesize": null,
        "productinfopreview":"Pavilion 15 CONVERTIBLE,touch screen,corei7,8 generation.."
    }
]